<template>
  <!-- Start of the modal import impression excel  -->
  <div
    v-if="showFilePrint"
    :class="`modal ${
      !showFilePrint && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="closeImport()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 mx-auto bg-white rounded shadow-lg modal-container max-h-128 max-w-8xl"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <div class="flex justify-between space-x-2 items-center">
            <div>
              <p class="text-2xl font-bold">Tableau de référence à imprimer</p>
            </div>
            <div class="flex flex-row items-center space-x-2">
              <select
                v-model="fournisseurSelected"
                placeholder="Fournisseur"
                style="font-size: 20px"
                @change="loadingCorrection = false"
                class="border-2 h-9 rounded-md focus:outline-sky-300 outline-2 font-bold"
              >
                <option
                  v-for="(f, indexF) in fournisseur"
                  :key="indexF"
                  :value="f.Prefixe_tarif"
                >
                  {{ f.CompanyName }}
                </option>
              </select>
              <span v-show="fournisseurNotSelected" class="text-red-500"
                >Veuillez choisir un fournisseur!</span
              >
            </div>
            <div v-show="loadingCorrection">Correction en cours ...</div>
          </div>
          <div class="z-50 cursor-pointer modal-close" @click="closeImport()">
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div class="flex" v-show="loadingFilesPrint">
          <loading-import />
        </div>
        <div v-show="!loadingFilesPrint">
          <div
            class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2 max-h-40"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[0]"
                      >
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                      </select>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[1]"
                      >
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                      </select>
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
                  <tr v-for="(readCondi, index) in readFilePrint" :key="index">
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[0] }}
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[1] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex justify-end space-x-2">
            <button
              class="px-4 py-2 text-white bg-orange-500 rounded hover:bg-blue-600"
              @click="readFileFormatingPrint()"
              v-if="tabHeader.length > 1 && !loadingCorrection"
            >
              Corriger le tableau
            </button>
            <button
              class="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
              @click="filePrint()"
              v-if="tabHeader.length > 1 && !loadingCorrection"
            >
              Générer le pdf
            </button>
          </div>
        </div>

        <!-- End Body -->
      </div>
    </div>
  </div>
  <!-- End of modal import impression excel  -->

  <!-- Start of the modal import excel  -->
  <div
    v-if="showFile"
    :class="`modal ${
      !showFile && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="closeImport()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 mx-auto bg-white rounded shadow-lg modal-container max-h-128 max-w-8xl"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <div class="flex flex-row space-x-2 items-center">
            <div>
              <p class="text-2xl font-bold">Tableau de stock à importer</p>
            </div>
            <div class="flex flex-row items-center space-x-2">
              <select v-model="fournisseurSelected">
                <option
                  v-for="(f, indexF) in fournisseur"
                  :key="indexF"
                  :value="f.Prefixe_tarif"
                >
                  {{ f.CompanyName }}
                </option>
              </select>
              <span v-show="fournisseurNotSelected" class="text-red-500"
                >Veuillez choisir un fournisseur!</span
              >
            </div>
            <div v-show="loadingCorrection">Correction en cours ...</div>
          </div>
          <div class="z-50 cursor-pointer modal-close" @click="closeImport()">
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div class="flex" v-show="loadingFiles">
          <loading-import />
        </div>
        <div v-show="!loadingFiles">
          <div
            class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2 max-h-40"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[0]"
                      >
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option class="bg-white text-gray-800" value="Adresse">
                          Adresse
                        </option>
                        <option class="bg-white text-gray-800" value="min">
                          min
                        </option>
                        <option class="bg-white text-gray-800" value="max">
                          max
                        </option>
                        <option class="bg-white text-gray-800" value="Stock">
                          Stock
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Prefixe_tarif"
                        >
                          Prefixe_tarif
                        </option>
                      </select>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[1]"
                      >
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option class="bg-white text-gray-800" value="Adresse">
                          Adresse
                        </option>
                        <option class="bg-white text-gray-800" value="min">
                          min
                        </option>
                        <option class="bg-white text-gray-800" value="max">
                          max
                        </option>
                        <option class="bg-white text-gray-800" value="Stock">
                          Stock
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Prefixe_tarif"
                        >
                          Prefixe_tarif
                        </option>
                      </select>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[2]"
                      >
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option class="bg-white text-gray-800" value="Adresse">
                          Adresse
                        </option>
                        <option class="bg-white text-gray-800" value="min">
                          min
                        </option>
                        <option class="bg-white text-gray-800" value="max">
                          max
                        </option>
                        <option class="bg-white text-gray-800" value="Stock">
                          Stock
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Prefixe_tarif"
                        >
                          Prefixe_tarif
                        </option>
                      </select>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[3]"
                      >
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option class="bg-white text-gray-800" value="Adresse">
                          Adresse
                        </option>
                        <option class="bg-white text-gray-800" value="min">
                          min
                        </option>
                        <option class="bg-white text-gray-800" value="max">
                          max
                        </option>
                        <option class="bg-white text-gray-800" value="Stock">
                          Stock
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Prefixe_tarif"
                        >
                          Prefixe_tarif
                        </option>
                      </select>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[4]"
                      >
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option class="bg-white text-gray-800" value="Adresse">
                          Adresse
                        </option>
                        <option class="bg-white text-gray-800" value="min">
                          min
                        </option>
                        <option class="bg-white text-gray-800" value="max">
                          max
                        </option>
                        <option class="bg-white text-gray-800" value="Stock">
                          Stock
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Prefixe_tarif"
                        >
                          Prefixe_tarif
                        </option>
                      </select>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[5]"
                      >
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option class="bg-white text-gray-800" value="Adresse">
                          Adresse
                        </option>
                        <option class="bg-white text-gray-800" value="min">
                          min
                        </option>
                        <option class="bg-white text-gray-800" value="max">
                          max
                        </option>
                        <option class="bg-white text-gray-800" value="Stock">
                          Stock
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Prefixe_tarif"
                        >
                          Prefixe_tarif
                        </option>
                      </select>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                    >
                      <select
                        class="form-select block w-full mt-1 text-white text-base bg-vk"
                        v-model="tabHeader[6]"
                      >
                        <option
                          class="bg-white text-gray-800"
                          value="Ref_fournisseur"
                        >
                          Ref_fournisseur
                        </option>
                        <option class="bg-white text-gray-800" value="Code_EAN">
                          Code_EAN
                        </option>
                        <option class="bg-white text-gray-800" value="Adresse">
                          Adresse
                        </option>
                        <option class="bg-white text-gray-800" value="min">
                          min
                        </option>
                        <option class="bg-white text-gray-800" value="max">
                          max
                        </option>
                        <option class="bg-white text-gray-800" value="Stock">
                          Stock
                        </option>
                        <option
                          class="bg-white text-gray-800"
                          value="Prefixe_tarif"
                        >
                          Prefixe_tarif
                        </option>
                      </select>
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
                  <tr v-for="(readCondi, index) in readFile" :key="index">
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[0] }}
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[1] }}
                    </td>
                    <td
                      class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[2] }}
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[3] }}
                    </td>
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[4] }}
                    </td>
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[5] }}
                    </td>
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[6] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex justify-end space-x-2">
            <button
              class="px-4 py-2 text-white bg-orange-500 rounded hover:bg-blue-600"
              @click="readFileFormating()"
              v-if="tabHeader.length > 5 && !loadingCorrection"
            >
              Corriger le tableau
            </button>
            <button
              class="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
              @click="importFile()"
              v-if="tabHeader.length > 5 && !loadingCorrection"
            >
              Importer
            </button>
          </div>
        </div>

        <!-- End Body -->
      </div>
    </div>
  </div>
  <!-- End of modal import excel  -->

  <!-- BODY -->
  <div class="flex items-center justify-between">
    <router-link to="/articlehub" class="flex justify-start w-fit rounded-md">
      <div id="retour">
        <backButton />
      </div>
    </router-link>

    <h5 class="text-gray-700" style="font-size: 30px">Etat des stocks</h5>

    <!-- Boutons à droite -->
    <div class="flex flex-row space-x-2 items-center">
      <button
        class="cursor-pointer bg-orange-600 hover:bg-orange-500 text-white shadow-md px-2 py-2 flex justify-between rounded-md"
        @click="document.getElementById('dropzone-file-print').click()"
      >
        <span class="mr-2">Impression étiquettes</span>
        <span class="material-icons-outlined m-auto">qr_code_scanner</span>
      </button>

      <input
        id="dropzone-file-print"
        type="file"
        class="hidden"
        accept=".xlsx, .xls, .csv"
        @change="readExcelPrint()"
      />
      <!-- <button
          class="flex items-center p-2 bg-green-600 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
          @click="document.getElementById('dropzone-file').click()"
        >
          <span class="mr-2">Importer excel</span>
          <span class="material-icons-outlined m-auto">file_upload</span>
        </button>

        <input
          id="dropzone-file"
          type="file"
          class="hidden"
          accept=".xlsx, .xls, .csv"
          @change="readExcel()"
        /> -->
      <label>
        <button
          v-if="loadingExport"
          class="flex bg-blue-500 p-2 text-white rounded shadow"
        >
          <PulseLoader color="#ffffff" />
          <h4>Exportation</h4>
        </button>
        <button
          v-else
          class="flex items-center bg-bluevk p-2 text-white shadow-md px-2 py-2 flex justify-between rounded-md"
          @click="exportExcel()"
        >
          <h4 class="mr-2">Exporter en Excel</h4>
          <span class="material-icons-outlined m-auto">file_download</span>
        </button></label
      >
    </div>
  </div>

  <div
    v-if="
      showFilter.codeEAN ||
      showFilter.codeMarque ||
      showFilter.prefixeTarif ||
      showFilter.stock ||
      showFilter.reserve ||
      showFilter.commandeFournisseur ||
      showFilter.minmax
    "
    @click="cleanFilter()"
    class="absolute w-screen h-screen z-10 top-0 left-0"
  ></div>

  <!-- FILTRES -->
  <div class="flex justify-between h-12">
    <div class="flex space-x-2">
      <div
        class="flex flex-row border space-x-2 items-center p-2 bg-gray-100 rounded-sm shadow-lg w-fit"
        v-if="searchTerm && searchTerm != null"
      >
        <span class="">{{ searchField }} : {{ searchTerm }}</span>
        <button
          class="material-icons-outlined cursor-pointer text-base"
          @click="deleteFilters(searchTerm, null)"
        >
          clear
        </button>
      </div>
      <div
        class="flex flex-row border space-x-2 items-center p-2 bg-gray-100 rounded-sm shadow-lg w-fit"
        v-if="sortBy && sortBy == 'stock' && sortOrder && sortOrder != null"
      >
        <span class="">{{
          sortOrder == 1 ? "Ordre croissant" : "Ordre décroissant"
        }}</span>
        <button
          class="material-icons-outlined cursor-pointer text-base"
          @click="deleteFilters(null, sortOrder)"
        >
          clear
        </button>
      </div>
    </div>
    <button
      class="text-right text-bluevk hover:text-blue-500 underline"
      @click="deleteFilters(searchTerm, sortOrder)"
    >
      Effacer les filtres
    </button>
  </div>

  <!-- TABLEAU -->
  <div>
    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border border-gray-400 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="relative border-r px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-between">
                    <div class="my-auto">
                      <p>EAN</p>
                      <p>Référence</p>
                    </div>
                    <button
                      @click="toggleFilter('codeEAN')"
                      class="material-icons-outlined text-orangevk text-md"
                    >
                      filter_alt
                    </button>
                  </div>

                  <!-- Recherche par EAN / Référence -->
                  <div
                    v-if="showFilter.codeEAN"
                    class="absolute -ml-2 -mt-6 w-full text-base bg-white z-20 border border-orange-500 rounded-md shadow-lg p-2"
                  >
                    <!-- EAN -->
                    <div class="relative">
                      <div class="relative">
                        <input
                          type="text"
                          id="EAN"
                          v-model="filters.codeEAN"
                          @keyup.enter="
                            filters.codeEAN
                              ? searchByField(
                                  'Code_EAN',
                                  filters.codeEAN,
                                  '_id',
                                  -1,
                                  1,
                                  10
                                )
                              : null
                          "
                          class="block px-2.5 pb-1 pt-2 w-full text-gray-900 bg-white rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                        />
                        <label
                          for="EAN"
                          class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >EAN</label
                        >
                      </div>
                      <span
                        class="material-icons-outlined absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                        @click="
                          filters.codeEAN
                            ? searchByField(
                                'Code_EAN',
                                filters.codeEAN,
                                '_id',
                                -1,
                                1,
                                10
                              )
                            : null
                        "
                      >
                        search
                      </span>
                    </div>
                    <!-- Référence -->
                    <div class="relative mt-2">
                      <div class="relative">
                        <input
                          type="text"
                          id="ref"
                          v-model="filters.reference"
                          @keyup.enter="
                            filters.reference
                              ? searchByField(
                                  'Ref_fournisseur',
                                  filters.reference,
                                  '_id',
                                  -1,
                                  1,
                                  10
                                )
                              : null
                          "
                          class="block px-2.5 pb-1 pt-2 w-full text-gray-900 bg-white rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                        />
                        <label
                          for="ref"
                          class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >Référence</label
                        >
                      </div>
                      <span
                        class="material-icons-outlined absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                        @click="
                          filters.reference
                            ? searchByField(
                                'Ref_fournisseur',
                                filters.reference,
                                '_id',
                                -1,
                                1,
                                10
                              )
                            : null
                        "
                      >
                        search
                      </span>
                    </div>
                  </div>
                </th>

                <!-- Recherche par marque -->
                <th
                  class="relative border-r px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-between">
                    <p class="my-auto">Marque</p>
                    <button
                      @click="toggleFilter('codeMarque')"
                      class="material-icons-outlined text-orangevk text-md"
                    >
                      filter_alt
                    </button>
                  </div>
                  <!-- Marque -->
                  <div
                    v-if="showFilter.codeMarque"
                    class="absolute -ml-2 -mt-6 w-full text-base bg-white z-20 border border-orange-500 rounded-md shadow-lg p-2"
                  >
                    <div class="relative">
                      <input
                        type="text"
                        id="codeMarque"
                        v-model="filters.codeMarque"
                        @keyup.enter="
                          filters.codeMarque
                            ? searchByField(
                                'Code_marque',
                                filters.codeMarque,
                                '_id',
                                -1,
                                1,
                                10
                              )
                            : null
                        "
                        class="block px-2.5 pb-1 pt-2 w-full text-gray-900 bg-white rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                      />
                      <label
                        for="codeMarque"
                        class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >Marque</label
                      >
                    </div>
                    <span
                      class="material-icons-outlined absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                      @click="
                        filters.codeMarque
                          ? searchByField(
                              'Code_marque',
                              filters.codeMarque,
                              '_id',
                              -1,
                              1,
                              10
                            )
                          : null
                      "
                    >
                      search
                    </span>
                  </div>
                </th>

                <!-- Recherche par préfixe tarif -->
                <th
                  class="relative border-r px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-between">
                    <p class="my-auto">Préfixe tarif</p>
                    <button
                      @click="toggleFilter('prefixeTarif')"
                      class="material-icons-outlined text-orangevk text-md"
                    >
                      filter_alt
                    </button>
                  </div>
                  <!-- Préfixe tarif -->
                  <div
                    v-if="showFilter.prefixeTarif"
                    class="absolute -ml-2 -mt-6 w-full text-base bg-white z-20 border border-orange-500 rounded-md shadow-lg p-2"
                  >
                    <div class="relative">
                      <input
                        type="text"
                        id="prefixe_tarif"
                        v-model="filters.prefixeTarif"
                        @keyup.enter="
                          filters.prefixeTarif
                            ? searchByField(
                                'Prefixe_tarif',
                                filters.prefixeTarif,
                                '_id',
                                -1,
                                1,
                                10
                              )
                            : null
                        "
                        class="block px-2.5 pb-1 pt-2 w-full text-gray-900 bg-white rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                      />
                      <label
                        for="prefixe_tarif"
                        class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >Préfixe Tarif</label
                      >
                    </div>
                    <span
                      class="material-icons-outlined absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                      @click="
                        filters.prefixeTarif
                          ? searchByField(
                              'Prefixe_tarif',
                              filters.prefixeTarif,
                              '_id',
                              -1,
                              1,
                              10
                            )
                          : null
                      "
                    >
                      search
                    </span>
                  </div>
                </th>

                <!-- Recherche par stock -->
                <th
                  class="relative border-r px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-between">
                    <p class="my-auto">Stock</p>
                    <button
                      @click="toggleFilter('stock')"
                      class="material-icons-outlined text-orangevk text-md"
                    >
                      filter_alt
                    </button>
                  </div>
                  <!-- Stock -->
                  <div
                    v-if="showFilter.stock"
                    class="absolute -ml-2 -mt-6 w-full text-base bg-white z-20 border border-orange-500 rounded-md shadow-lg p-2"
                  >
                    <div class="relative">
                      <div class="relative">
                        <input
                          type="text"
                          id="stock"
                          v-model="filters.stock"
                          @keyup.enter="
                            filters.stock
                              ? searchByField(
                                  'stock',
                                  parseInt(filters.stock),
                                  '_id',
                                  -1,
                                  1,
                                  10
                                )
                              : null
                          "
                          class="block px-2.5 pb-1 pt-2 w-full text-gray-900 bg-white rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                        />
                        <label
                          for="stock"
                          class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >Stock</label
                        >
                      </div>
                      <span
                        class="material-icons-outlined absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                        @click="
                          filters.stock
                            ? searchByField(
                                'stock',
                                parseInt(filters.stock),
                                '_id',
                                -1,
                                1,
                                10
                              )
                            : null
                        "
                      >
                        search
                      </span>
                    </div>
                    <div class="flex flex-col text-sm">
                      <button
                        class="hover:text-blue-500 flex justify-between text-bluevk"
                        @click="
                          filters.stock
                            ? searchByField(
                                'stock',
                                parseInt(filters.stock),
                                'stock',
                                1,
                                1,
                                10
                              )
                            : searchByField(null, null, 'stock', 1, 1, 10)
                        "
                      >
                        <p>Croissant</p>
                        <span class="material-icons-round">
                          keyboard_arrow_up
                        </span>
                      </button>
                      <button
                        class="hover:text-blue-500 flex justify-between text-bluevk"
                        @click="
                          filters.stock
                            ? searchByField(
                                'stock',
                                parseInt(filters.stock),
                                'stock',
                                -1,
                                1,
                                10
                              )
                            : searchByField(null, null, 'stock', -1, 1, 10)
                        "
                      >
                        <p>Décroissant</p>
                        <span class="material-icons-round">
                          keyboard_arrow_down
                        </span>
                      </button>
                    </div>
                  </div>
                </th>

                <!-- Recherche par réservé -->
                <th
                  class="relative border-r px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-between">
                    <p class="my-auto">Réservé</p>
                    <button
                      @click="toggleFilter('reserve')"
                      class="material-icons-outlined text-orangevk text-md"
                    >
                      filter_alt
                    </button>
                  </div>
                  <!-- Réservé -->
                  <div
                    v-if="showFilter.reserve"
                    class="absolute -ml-2 -mt-6 w-full text-base bg-white z-20 border border-orange-500 rounded-md shadow-lg p-2"
                  >
                    <div class="relative">
                      <input
                        type="number"
                        id="text"
                        v-model="filters.reserve"
                        @keyup.enter="
                          filters.reserve
                            ? searchByField(
                                'reserve',
                                parseInt(filters.reserve),
                                '_id',
                                -1,
                                1,
                                10
                              )
                            : null
                        "
                        class="block px-2.5 pb-1 pt-2 w-full text-gray-900 bg-white rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                      />
                      <label
                        for="reserve"
                        class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >Réservé</label
                      >
                    </div>
                    <span
                      class="material-icons-outlined absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                      @click="
                        filters.reserve
                          ? searchByField(
                              'reserve',
                              parseInt(filters.reserve),
                              '_id',
                              -1,
                              1,
                              10
                            )
                          : null
                      "
                    >
                      search
                    </span>
                  </div>
                </th>

                <!-- Recherche par commande fournisseur -->
                <th
                  class="relative border-r px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-between">
                    <p class="my-auto">Commandé</p>
                    <button
                      @click="toggleFilter('commandeFournisseur')"
                      class="material-icons-outlined text-orangevk text-md"
                    >
                      filter_alt
                    </button>
                  </div>
                  <!-- Commande fournisseur -->
                  <div
                    v-if="showFilter.commandeFournisseur"
                    class="absolute -ml-2 -mt-6 w-full text-base bg-white z-20 border border-orange-500 rounded-md shadow-lg p-2"
                  >
                    <div class="relative">
                      <input
                        type="text"
                        id="commandeFournisseur"
                        v-model="filters.commandeFournisseur"
                        @keyup.enter="
                          filters.commandeFournisseur
                            ? searchByField(
                                'backorder',
                                parseInt(filters.commandeFournisseur),
                                '_id',
                                -1,
                                1,
                                10
                              )
                            : null
                        "
                        class="block px-2.5 pb-1 pt-2 w-full text-gray-900 bg-white rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                      />
                      <label
                        for="commandeFournisseur"
                        class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >Commandé</label
                      >
                    </div>
                    <span
                      class="material-icons-outlined absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                      @click="
                        filters.commandeFournisseur
                          ? searchByField(
                              'backorder',
                              parseInt(filters.commandeFournisseur),
                              '_id',
                              -1,
                              1,
                              10
                            )
                          : null
                      "
                    >
                      search
                    </span>
                  </div>
                </th>

                <!-- Rercherche par Min | Max -->
                <th
                  class="relative border-r px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-between">
                    <p class="my-auto">Min | Max</p>
                    <button
                      @click="toggleFilter('minmax')"
                      class="material-icons-outlined text-orangevk text-md"
                    >
                      filter_alt
                    </button>
                  </div>
                  <!-- Min | Max -->
                  <div
                    v-if="showFilter.minmax"
                    class="absolute -ml-2 -mt-6 w-full text-base bg-white z-20 border border-orange-500 rounded-md shadow-lg p-2"
                  >
                    <!-- Min -->
                    <div class="relative">
                      <div class="relative">
                        <input
                          type="text"
                          id="min"
                          v-model="filters.min"
                          @keyup.enter="
                            filters.min
                              ? searchByField(
                                  'min',
                                  parseInt(filters.min),
                                  '_id',
                                  -1,
                                  1,
                                  10
                                )
                              : null
                          "
                          class="block px-2.5 pb-1 pt-2 w-full text-gray-900 bg-white rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                        />
                        <label
                          for="min"
                          class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >Min</label
                        >
                      </div>
                      <span
                        class="material-icons-outlined absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                        @click="
                          filters.min
                            ? searchByField(
                                'min',
                                parseInt(filters.min),
                                '_id',
                                -1,
                                1,
                                10
                              )
                            : null
                        "
                      >
                        search
                      </span>
                    </div>
                    <!-- Max -->
                    <div class="relative mt-2">
                      <div class="relative">
                        <input
                          type="text"
                          id="max"
                          v-model="filters.max"
                          @keyup.enter="
                            filters.max
                              ? searchByField(
                                  'max',
                                  parseInt(filters.max),
                                  '_id',
                                  -1,
                                  1,
                                  10
                                )
                              : null
                          "
                          class="block px-2.5 pb-1 pt-2 w-full text-gray-900 bg-white rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                        />
                        <label
                          for="max"
                          class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >Max</label
                        >
                      </div>
                      <span
                        class="material-icons-outlined absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                        @click="
                          filters.max
                            ? searchByField(
                                'max',
                                parseInt(filters.max),
                                '_id',
                                -1,
                                1,
                                10
                              )
                            : null
                        "
                      >
                        search
                      </span>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(u, index) in Stock" :key="index">
                <td
                  class="px-6 py-4 leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex justify-between">
                    <div class="flex flex-col">
                      <div class="text-sm leading-5 text-gray-900">
                        {{ u.Code_EAN }}
                      </div>
                      <div class="font-medium leading-5 text-gray-900">
                        {{ u.Ref_fournisseur }}
                      </div>
                    </div>
                    <div>
                      <span
                        class="material-icons-outlined text-blue-500 cursor-pointer"
                        @click="
                          getEtiquetaRangement(u.Code_EAN, u.Ref_fournisseur)
                        "
                      >
                        qr_code_scanner
                      </span>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Code_marque }}
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Prefixe_tarif }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <p>{{ u.stock }}</p>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.reserved }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.backorder }}
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  Min : {{ u.min }} Max : {{ u.max }}
                </td>
              </tr>
            </tbody>
          </table>

          <pageNumber
            v-if="searchActif"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="stockList"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(stockList / 10)"
            :clickFunction="
              (arg1, arg2) =>
                searchByField(
                  searchField,
                  searchTerm,
                  sortBy,
                  sortOrder,
                  arg1,
                  arg2
                )
            "
            :currentPage="currentPage"
            :typeList="'articles'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />

          <pageNumber
            v-else
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="stockList"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(stockList / 10)"
            :clickFunction="getPage"
            :currentPage="currentPage"
            :typeList="'articles'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import readXlsxFile from "read-excel-file";
import * as XLSX from "xlsx";
import { useToast } from "vue-toastification";
import loadingImport from "../components/general/importFiles/loadingImport.vue";
import pageNumber from "../components/pageNumber.vue";
import {
  etiquetteRangement,
  etiquetteExcelRangement,
} from "@/hooks/pdf/etiquetteRangement.js";
import backButton from "@/components/Button/back.vue";

export default {
  data() {
    return {
      allStock: [],
      loadingExport: false,

      Stock: [],
      readFile: null,
      file: null,
      showFile: false,
      loadingFiles: false,
      tabHeader: [],
      fournisseur: [],
      fournisseurNotSelected: false,
      fournisseurSelected: "",
      loadingCorrection: false,
      readFilePrint: null,
      showFilePrint: false,
      loadingFilesPrint: false,

      stockList: 0,
      pageTab: [],
      pageNext: 11,
      pageStart: 1,

      currentPage: 1,
      showFilter: {
        codeEAN: false,
        codeMarque: false,
        prefixeTarif: false,
        stock: false,
        reserve: false,
        commandeFournisseur: false,
        minmax: false,
      },
      filters: {
        codeEAN: null,
        reference: null,
        codeMarque: null,
        prefixeTarif: null,
        stock: null,
        reserve: null,
        commandeFournisseur: null,
        minmax: null,
        sortBy: null,
        sortOrder: null,
      },
      searchActif: false,
      searchField: null,
      searchTerm: null,
      sortBy: null,
      sortOrder: null,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    loadingImport,
    pageNumber,
    backButton,
    PulseLoader,
  },
  computed: {
    ...mapGetters(["user", "comptoirSessionActive"]),
  },
  methods: {
    getPage(skipValue, limitValue) {
      this.currentPage = skipValue;
      axios
        .post(
          `${process.env.VUE_APP_API}/stock/articles/${this.user.proprietaire}`,
          {
            skip: (skipValue - 1) * 10,
            limit: limitValue,
          }
        )
        .then((res) => {
          if (res.data.length > 0) {
            this.Stock = res.data;
          } else {
            this.error("Aucun résultat");
          }
        });
    },
    getTotal() {
      axios
        .get(`${process.env.VUE_APP_API}/stock/count/${this.user.proprietaire}`)
        .then((res) => {
          this.stockList = res.data;
        });
    },
    async searchByField(
      searchField,
      searchTerm,
      sortBy,
      sortOrder,
      skipValue,
      limitValue,
      exportation
    ) {
      // if (searchTerm) {
      this.searchActif = true;
      this.searchField = searchField;
      this.searchTerm = searchTerm;
      this.sortBy = sortBy;
      this.sortOrder = sortOrder;
      this.currentPage = skipValue;
      await axios
        .post(`${process.env.VUE_APP_API}/stock/filterStateStock`, {
          plateform: this.user.proprietaire,
          searchField: searchField,
          searchTerm: searchTerm,
          sortBy: sortBy,
          sortOrder: sortOrder,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((res) => {
          if (res.data.stock.length > 0) {
            if (exportation) {
              this.allStock = res.data.stock;
            } else {
              this.Stock = res.data.stock;
              this.stockList = res.data.count;
            }
            this.showFilter = {
              codeEAN: false,
              codeMarque: false,
              prefixeTarif: false,
              stock: false,
              reserve: false,
              commandeFournisseur: false,
              minmax: false,
            };
          } else {
            this.error("Aucun résultat");
          }
        });
      // } else {
      //   this.searchActif = false;
      //   this.getPage(skipValue, limitValue);
      //   this.getTotal();
      // }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    async readExcel() {
      var file = event.target.files ? event.target.files[0] : null;
      console.log(file);
      if (file.size < 1000000) {
        console.log("showfile");
        this.loadingFiles = true;
        this.showFile = true;
        this.readFile = await readXlsxFile(file);
        this.readFile.splice(0, 1);
        this.loadingFiles = false;
      } else {
        this.error("Le fichier est trop volumineux");
      }
      console.log(this.tabHeader);
    },
    async readExcelPrint() {
      var file = event.target.files ? event.target.files[0] : null;
      if (file.size < 1000000) {
        console.log("showfileprint");
        this.loadingFilesPrint = true;
        this.showFilePrint = true;
        this.readFilePrint = await readXlsxFile(file);
        this.readFilePrint.splice(0, 1);
        this.loadingFilesPrint = false;
      } else {
        this.error("Le fichier est trop volumineux");
      }
      console.log(this.tabHeader);
    },
    importFile() {
      if (this.controlHeaderTab(this.tabHeader)) {
        this.loadingFiles = true;
        if (this.readFile.length > 0) {
          axios
            .post(`${process.env.VUE_APP_API}/stock/importStock`, {
              Data: this.readFile,
              plateform: this.user.proprietaire,
              headerTab: this.tabHeader,
            })
            .then((res) => {
              this.loadingFiles = false;
              this.showFile = false;
              this.getStock();
            });
        } else {
          this.error("Le fichier est vide");
          this.loadingFiles = false;
        }
      } else {
        this.error("Les colonnes ne doivent pas être identiques");
      }
    },
    async filePrint() {
      if (this.controlHeaderTab(this.tabHeader)) {
        this.loadingFilesPrint = true;
        console.log("readFilePrint", this.readFilePrint);
        if (this.readFilePrint.length > 0) {
          await axios
            .post(`${process.env.VUE_APP_API}/stock/importPrint`, {
              Data: this.readFilePrint,
              plateform: this.user.proprietaire,
              headerTab: this.tabHeader,
            })
            .then((res) => {
              console.log("res", res);
              this.getLabelExcel(res.data);
              this.loadingFilesPrint = false;
              this.showFilePrint = false;
            });
        } else {
          this.error("Le fichier est vide");
          this.loadingFilesPrint = false;
        }
      } else {
        this.error("Les colonnes ne doivent pas être identiques");
      }
    },
    async readFileFormating() {
      if (this.fournisseurSelected == "") {
        this.fournisseurNotSelected = true;
      } else {
        this.fournisseurNotSelected = false;
        this.loadingCorrection = true;
        axios
          .post(`${process.env.VUE_APP_API}/stock/excelFormatting`, {
            tabHeader: this.tabHeader,
            plateform: this.user.proprietaire,
            data: this.readFile,
            fournisseur: this.fournisseurSelected,
          })
          .then((res) => {
            console.log(res.data);
            this.readFile = res.data;
            this.loadingCorrection = false;
          });
      }
    },
    async readFileFormatingPrint() {
      if (this.fournisseurSelected == "") {
        this.fournisseurNotSelected = true;
      } else {
        this.fournisseurNotSelected = false;
        this.loadingCorrection = true;
        axios
          .post(`${process.env.VUE_APP_API}/stock/excelFormattingPrint`, {
            tabHeader: this.tabHeader,
            plateform: this.user.proprietaire,
            data: this.readFilePrint,
            fournisseur: this.fournisseurSelected,
          })
          .then((res) => {
            console.log(res.data);
            this.readFilePrint = res.data;
            this.loadingCorrection = false;
          });
      }
    },
    controlHeaderTab(arr) {
      for (let i = 0; i < arr.length - 1; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    },
    closeImport() {
      this.showFile = false;
      this.file = null;
      this.readFile = null;
      this.showFilePrint = false;
      this.readFilePrint = null;
    },
    async exportExcel() {
      this.loadingExport = true;
      await this.searchByField(
        this.searchField,
        this.searchTerm,
        this.sortBy,
        this.sortOrder,
        1,
        this.stockList,
        true
      );

      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(this.allStock);
      XLSX.utils.book_append_sheet(wb, ws, "Stock");

      XLSX.writeFile(wb, "Stock_" + this.user.proprietaire + ".xlsx");
      this.loadingExport = false;

      this.success("Le fichier a été téléchargé");
    },
    getEtiquetaRangement(ean, Ref_fournisseur) {
      etiquetteRangement(ean, Ref_fournisseur);
    },
    getLabelExcel(objet) {
      console.log(objet);
      etiquetteExcelRangement(objet);
    },
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.proprietaire,
        })
        .then((res) => {
          this.fournisseur = res.data;
        });
    },
    success(message) {
      this.toast.success(message, {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.warning(message, {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    toggleFilter(column) {
      this.showFilter[column] = true;
    },
    cleanFilter() {
      this.showFilter = {
        codeEAN: false,
        codeMarque: false,
        prefixeTarif: false,
        stock: false,
        reserve: false,
        commandeFournisseur: false,
        minmax: false,
      };

      this.filters = {
        codeEAN: "",
        reference: "",
        codeMarque: "",
        prefixeTarif: "",
        stock: "",
        reserve: "",
        commandeFournisseur: "",
        minmax: "",
        sortBy: "",
        sortOrder: "",
      };
    },
    deleteFilters(searchTerm, sortOrder) {
      if (searchTerm) {
        this.searchField = null;
        this.searchTerm = null;
      }
      if (sortOrder) {
        this.sortBy = null;
        this.sortOrder = null;
      }
      this.searchActif = false;
      this.getPage(1, 10);
      this.getTotal();
    },
  },
  mounted() {
    this.getPage(1, 10);
    this.getTotal();
    this.pageSwitch();
    this.getFournisseur();
  },
};
</script>
<style scoped>
.bg-vk {
  background-color: #2262b3;
}
</style>
