<template>
  <!-- Facturation fin de mois -->
  <!-- <h4 class="text-3xl">Facturation fin de mois</h4> -->
  <div v-if="!regrouperBdl" class="flex justify-center border">
    <div class="flex space-x-12 bg-gray-100 rounded-md shadow my-2 p-6 w-fit">
      <div class="flex flex-col">
        <label for="dateInput" class="text-sm">Date d'édition du facture</label>
        <input
          id="dateInput"
          type="datetime-local"
          v-model="editionFacture"
          class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
        />
      </div>
      <div class="flex flex-col">
        <label for="clientInput" class="text-sm">Nom du client</label>
        <input
          list="clientInput"
          type="text"
          v-model="nomClient"
          @input="updateClientId"
          class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
        />
        <datalist id="clientInput">
          <option
            v-for="(c, index) in Clients"
            :key="index"
            :value="c.Name"
          ></option>
        </datalist>
      </div>
      <div class="flex flex-col">
        <label for="numeroBLInput" class="text-sm">Numéro de BL</label>
        <input
          list="numeroBLInput"
          type="text"
          v-model="numBL"
          class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
        />
        <datalist id="numeroBLInput">
          <option
            v-for="(num, index) in getNumerosBL"
            :key="index"
            :value="num"
          ></option>
        </datalist>
      </div>
      <button
        v-if="!loadingRegroupement"
        class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
        @click="regrouperFDM()"
      >
        <span class="material-icons-outlined text-xl mr-2"> forward </span>
        <span>Valider</span>
      </button>
      <button
        v-else
        class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
      >
        <PulseLoader color="#ffff" />
        <span>En cours</span>
      </button>
    </div>
  </div>

  <!-- BDL regroupés -->
  <div v-else class="space-y-4">
    <PrintDialog
      v-if="printDialogVisible"
      :visible="printDialogVisible"
      :facturationSelected="{
        numeroFacturation:
          infosFacture[0].facturationFinDeMois.numeroFacturation,
      }"
      @cancel="handleCancelPrint"
    />

    <!-- Création Facture -->
    <div class="flex justify-between">
      <button
        id="retour"
        class="flex justify-start my-auto bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400"
        @click="
          (regrouperBdl = false),
            (creation = false),
            $emit('regroupement', false)
        "
      >
        <img src="../../assets/RetourIcon.png" class="w-6" />
        <span style="color: #5976e8">Retour</span>
      </button>

      <div class="flex">
        <button
          v-if="creation == true && infosFacture.length > 0"
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
          @click="printPDF()"
        >
          <span class="material-icons-outlined text-xl mr-2"> printer </span>
          <span>Imprimer</span>
        </button>

        <!-- Envoyer -->
        <div
          v-if="infosFacture.length > 0"
          class="relative flex justify-center"
        >
          <div
            v-show="showDmdEnvoi"
            @click="showDmdEnvoi = false"
            class="absolute w-screen h-screen z-10 top-0 left-0"
          ></div>
          <button
            v-if="creation == true && sending == false"
            class="bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
            @click="showDmdEnvoi = true"
          >
            <span class="material-icons-outlined text-xl mr-2"> mail </span>
            Envoyer une par une
          </button>
          <button
            v-else-if="creation == true && sending == true"
            class="bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
          >
            <PulseLoader color="#ffff" class="mr-2" />
            <span>Envoi en cours </span>
          </button>
          <div
            v-if="showDmdEnvoi"
            class="absolute mt-8 -ml-8 bg-gray-200 p-2 rounded shadow-lg z-10 overflow-hidden"
          >
            <div class="flex flex-col justify-center space-x-4">
              <span
                >Êtes-vous sûr de vouloir envoyer toutes les factures aux
                clients ?</span
              >
              <div class="flex space-x-4 justify-center">
                <button
                  class="bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
                  @click="sendEmail()"
                >
                  Oui
                </button>
                <button
                  class="text-bluevk hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
                  @click="showDmdEnvoi = false"
                >
                  Non
                </button>
              </div>
            </div>
          </div>
        </div>

        <button
          v-if="creation == false"
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
          @click="createFactures()"
        >
          <span class="material-icons-outlined text-xl mr-2"> done_all </span>
          <span>Créer</span>
        </button>
      </div>
    </div>

    <!-- Récapitulatif BL -->
    <div v-if="creation == false">
      <div class="flex space-x-2 bg-orange-400 w-fit rounded-md px-2">
        <p>Date d'édition choisie :</p>
        <p class="font-medium">
          {{ editionFacture }}
        </p>
      </div>
      <div class="mt-8 space-y-2">
        <div class="flex justify-between">
          <h2 class="text-xl font-medium">
            Récapitulatif des bons de livraison à regrouper :
          </h2>
          <p>Nombre total de facture à créer : {{ recapBdls.length }}</p>
        </div>

        <div class="inline-block flex justify-center">
          <table
            class="w-fit shadow align-middle border-b border-gray-200 sm:rounded-lg"
            style="max-height: 18vh"
          >
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50"
                >
                  Client
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50"
                >
                  Total HT BL
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50"
                >
                  Total HT BL Avoir
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50"
                >
                  Total HT
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50"
                >
                  Total Transport
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(u, index) in recapBdls"
                :key="index"
                class="cursor-pointer hover:bg-gray-100"
              >
                <td
                  class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.client.Name }}
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.totalBL }} €
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap border-r"
                >
                  {{ u.totalAvoir }} €
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap border-r"
                >
                  {{ u.totalPrice }} €
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap border-r"
                >
                  {{ u.totalShippingCosts }} €
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Création Facture -->
    <div v-if="creation == true">
      <div
        v-if="loading"
        class="flex justify-center w-full bg-white items-center space-x-2 mt-4"
      >
        <span>En cours de chargement</span>
        <PulseLoader color="#2d74da" />
      </div>
      <table class="min-w-full" v-if="!loading">
        <thead>
          <tr>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              <span>Référence client</span>
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              <span>Date</span>
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              <span>Numéro</span>
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              <span>Client</span>
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              <span>Créé par</span>
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              <span>Total Prix</span>
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              PDF
            </th>
          </tr>
        </thead>

        <tbody class="bg-white">
          <tr
            v-for="(u, index) in infosFacture"
            :key="index"
            class="cursor-pointer hover:bg-gray-100"
          >
            <td
              class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
            >
              {{ u.Note }}
            </td>
            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <div class="flex items-center">
                <div class="ml-4">
                  <div class="text-sm font-medium leading-5 text-gray-900">
                    {{ getFRDate(u.Date) }}
                  </div>
                </div>
              </div>
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <div class="text-sm leading-5 text-gray-900">
                {{ u.Numero }}
                <span
                  @click="(infoInFacture = true), getDetailFacture(u)"
                  title="Plus d'informations"
                  class="material-icons-outlined text-base text-cyan-600 cursor-pointer"
                >
                  info
                </span>
              </div>
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              {{ u.Client }}
            </td>

            <td
              class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
            >
              {{ u.Name }}
            </td>

            <td
              class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
            >
              {{ parseFloat(u.TotalPrice).toFixed(2) }}
            </td>

            <td
              class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
            >
              <div class="flex space-x-4">
                <span
                  class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                  @click="createPDF(u)"
                >
                  download
                </span>
              </div>
              <div
                v-if="loadingPDF"
                class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100 bg-opacity-20"
              >
                <div class="bg-white py-5 px-20 rounded border shadow">
                  <!-- <svg
                          class="animate-spin h-10 w-10 text-blue-500 mx-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path stroke="none" d="M0 0h24v24H0z"></path>
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="12" cy="5" r="1"></circle>
                          <circle cx="12" cy="19" r="1"></circle>
                        </svg> -->
                  <div class="loader"></div>
                  <p class="text-center mt-2">Chargement...</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { createfacture } from "@/hooks/pdf/facture";
import { printfacture, sendfacture } from "@/hooks/pdf/facturation";
import { createfacturepro } from "@/hooks/pdf/groupebdl";
import {
  regrouperBdl,
  regrouperBdlClientNum,
  regrouperBdlClient,
  regrouperBdlNum,
  getCartsForMonth,
  createFacture,
} from "@/hooks/searchEngine/factureMois";
import { useToast } from "vue-toastification";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
import PrintDialog from "@/components/Documents/printDialog.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyFacturation",
  data() {
    return {
      loadingRegroupement: false,
      regrouperBdl: false,
      editionFacture: null,
      nomClient: null,
      numBL: null,
      Clients: [],
      infosBdl: [],
      infosBdlsRegroupes: [],
      infosFacture: [],
      creation: false,
      loading: false,
      sending: false,
      showDmdEnvoi: false,
      loadingPDF: false,

      recapBdls: [],
      printDialogVisible: false,
      clientId: null,
    };
  },
  components: {
    PulseLoader,
    PrintDialog,
  },
  computed: {
    ...mapGetters([
      "user",
      "plateform",
      "dateEditionBdlsRegroupes",
      "recapBdlsRegroupes",
      "dateEcheanceBdlsRegroupes",
    ]),
    getNumerosBL() {
      var tabNum = [];
      this.infosBdl.forEach((element) => {
        if (this.nomClient) {
          if (this.nomClient == element.Client) {
            tabNum.push(element.Numero);
          }
        } else {
          tabNum.push(element.Numero);
        }
      });
      return tabNum;
    },
  },
  methods: {
    ...mapActions(["setBdlsRegroupes"]),
    getFRDate(date) {
      return getFrDate(date);
    },
    updateClientId() {
      const client = this.Clients.find((c) => c.Name === this.nomClient);
      this.clientId = client ? client._id : null;
    },
    getClients() {
      axios
        .get(`${process.env.VUE_APP_API}/client/${this.user.proprietaire}`)
        .then((response) => {
          this.Clients = response.data;
        });
    },
    getInfosBdl() {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getbyowner`, {
          uid: this.user.proprietaire,
        })
        .then((response) => {
          this.infosBdl = response.data;
          // this.Articles = response.data[index].Articles;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async regrouperFDM() {
      // Date d'aujourd'hui sous format yyyy-mm-dd
      const today = new Date();
      const todayYear = today.getFullYear();
      const todayMonth = String(today.getMonth() + 1).padStart(2, "0");
      const todayDay = String(today.getDate()).padStart(2, "0");
      const formattedToday = `${todayYear}-${todayMonth}-${todayDay}`;

      // Conversion de la date d'édition sous format yyyy-mm-dd
      const date = new Date(this.editionFacture);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const formattedDateEdition = `${year}-${month}-${day}`;

      const formattedDate = `${day}/${month}/${year} 23:59:59`;

      const dateToday = Date.parse(formattedToday);
      const dateLimite = Date.parse(formattedDateEdition);

      if (dateToday >= dateLimite) {
        this.loadingRegroupement = true;
        var dateDemandee = new Date(this.editionFacture);
        // Ajouter 30 jours
        var dateApres30Jours = new Date(
          dateDemandee.getTime() + 30 * 24 * 60 * 60 * 1000
        );
        // Formatage de la date
        var annee = dateApres30Jours.getFullYear();
        var mois = (dateApres30Jours.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        var jour = dateApres30Jours.getDate().toString().padStart(2, "0");
        var dateecheance = jour + "/" + mois + "/" + annee;

        // Regrouper par le nom du client et le numéro de BL
        // if (this.nomClient && this.numBL) {
        //   this.recapBdls = {
        //     tabBdls: await regrouperBdlClientNum(
        //       this.user.proprietaire,
        //       this.nomClient,
        //       this.numBL,
        //       formattedDate.substr(0, 10)
        //     ),
        //     dateEdition: date,
        //     dateEcheance: dateecheance,
        //   };
        // }
        // // Regrouper par le nom du client
        // else if (this.nomClient) {
        //   this.recapBdls = {
        //     tabBdls: await regrouperBdlClient(
        //       this.user.proprietaire,
        //       this.nomClient,
        //       formattedDate.substr(0, 10)
        //     ),
        //     dateEdition: date,
        //     dateEcheance: dateecheance,
        //   };
        // }
        // // Regrouper par le numéro de BL
        // else if (this.numBL) {
        //   this.recapBdls = {
        //     tabBdls: await regrouperBdlNum(
        //       this.user.proprietaire,
        //       this.numBL,
        //       formattedDate.substr(0, 10)
        //     ),
        //     dateEdition: date,
        //     dateEcheance: dateecheance,
        //   };
        // }
        // // Regrouper tout
        // else {
        //   this.recapBdls = {
        //     tabBdls: await regrouperBdl(
        //       this.user.proprietaire,
        //       formattedDate.substr(0, 10)
        //     ),
        //     dateEdition: date,
        //     dateEcheance: dateecheance,
        //   };
        // }
        const recap = await getCartsForMonth(
          this.clientId && this.clientId.length > 0 ? [this.clientId] : null,
          this.user.proprietaire,
          this.numBL,
          this.editionFacture
        );
        this.recapBdls = recap.cards;
        console.log(this.recapBdls);
        this.loadingRegroupement = false;
        this.$emit("regroupement", true);
        this.regrouperBdl = true;
      } else {
        this.toast.error("Vous ne pouvez pas facturer à cette date !", {
          position: "bottom-right",
          timeout: 1500,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async createFactures() {
      if (this.recapBdls.length > 0) {
        this.creation = true;
        this.loading = true;
        this.infosFacture = await createFacture(
          this.plateform,
          this.recapBdls,
          this.editionFacture,
          this.user.username
        );
        console.log(this.infosFacture);
        this.toast.success("Factures créées !", {
          position: "bottom-right",
          timeout: 1000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.loading = false;
      } else {
        this.toast.error("Bon de livraison non trouvé !", {
          position: "bottom-right",
          timeout: 1000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async createPDF(objet) {
      this.loadingPDF = true;
      if (objet.regroupe) {
        await this.createPDFfacture(objet._id);
      } else {
        if (objet.TotalPrice >= 0) {
          createfacture(objet, false, false);
        } else {
          createfacture(objet, false, true);
        }
      }
      this.loadingPDF = false;
    },
    printPDF() {
      this.printDialogVisible = true;
      // await axios
      //   .post(`${process.env.VUE_APP_API}/facture/getAllBdlsById`, {
      //     id: id,
      //   })
      //   .then((response) => {
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     this.loadingPDF = false;
      //   });
      // printfacture(this.infosFacture);
    },
    handleCancelPrint() {
      this.printDialogVisible = false;
    },
    async createPDFfacture(id) {
      await axios
        .post(`${process.env.VUE_APP_API}/facture/getAllBdlsById`, {
          id: id,
        })
        .then((response) => {
          if (response.data.TotalPrice >= 0) {
            createfacturepro(response.data, false, false);
          } else {
            createfacturepro(response.data, false, true);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingPDF = false;
        });
    },
    async sendEmail() {
      this.showDmdEnvoi = false;
      this.sending = true;
      for (const facture of this.infosFacture) {
        console.log(facture);
        await sendfacture(facture);
      }
      this.sending = false;
      this.toast.success("Facture(s) envoyée(s) !", {
        position: "bottom-right",
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    saveStatus(id, data) {
      axios.put(`${process.env.VUE_APP_API}/facture/${id}`, { status: data });
    },
  },
  mounted() {
    this.getInfosBdl();
    this.getClients();
    var dateActuelle = new Date();
    var premierJourDuMoisActuel = new Date(
      dateActuelle.getFullYear(),
      dateActuelle.getMonth(),
      1
    );
    var dernierJourDuMoisDernier = new Date(premierJourDuMoisActuel - 1);
    dernierJourDuMoisDernier.setHours(23, 59, 59, 999);
    var formatDate = dernierJourDuMoisDernier;
    console.log(formatDate);
    var dd = String(formatDate.getDate()).padStart(2, "0");
    var mm = String(formatDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = formatDate.getFullYear();
    var hh = formatDate.getHours();
    var ss = formatDate.getSeconds();
    var mmm = formatDate.getMinutes();
    this.editionFacture =
      yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + mmm + ":" + ss;
  },
};
</script>
