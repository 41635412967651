import { createRouter, createWebHistory } from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import store from "../store";
import Dashboard from "../views/Dashboard.vue";
import LoginPage from "../views/LoginPage.vue";
import Profil from "../views/Profil.vue";
// import NotFound from "../views/NotFound.vue";
import Articles from "../views/Articles.vue";
import Fournisseurs from "../views/Fournisseurs.vue";
import Preparation from "../views/Preparation.vue";
import commandePro from "../views/commandePro.vue";
import Enlevement from "../views/Enlevement.vue";
import Clients from "../views/Clients.vue";
import Precommande from "../views/Precommande.vue";
import Statistiques from "../views/Statistiques.vue";
import Base from "../views/Base.vue";
import Caisse from "../views/Caisse.vue";
import historiqueCaisse from "../views/historiqueCaisse.vue";
import Encaissement from "../views/Encaissement.vue";
import Utilisateurs from "../views/Utilisateurs.vue";
import MouvementStock from "../views/Articles/MouvementStock.vue";
import CatalogueDePneus from "../views/Articles/CatalogueDePneus.vue";
import FamilleArticle from "../views/Articles/Configuration/familleArticle.vue";
import SousFamilleArticle from "../views/Articles/Configuration/sousfamilleArticle.vue";
import actionClient from "../views/actionClient.vue";
import Devis from "../views/Clients/Devis.vue";
import Commandes from "../views/Clients/Commandes.vue";
import Bdl from "../views/Clients/Bdl.vue";
import deletedBdl from "../views/Clients/deletedBdl.vue";
import Bdt from "../views/Clients/Bdt.vue";
import GroupeBdl from "../views/Clients/GroupeBdl.vue";
import Consigne from "../views/Clients/Consigne.vue";
import Retour from "../views/Clients/Retour.vue";
import detailRetour from "../views/Clients/detailRetour.vue";
import Avoir from "../views/Clients/Avoir.vue";
import Facture from "../views/Clients/Facture.vue";
import Facturation from "../views/Clients/Facturation.vue";
import TecDoc from "../views/Catalogue/Tecdoc.vue";
import TecDocSearch from "../views/Catalogue/TecDocSearch.vue";
import CarCat from "../views/Catalogue/CarCat.vue";
import Promotions from "../views/Promotions.vue";
import Depot from "../views/Depot.vue";
import MatricePrix from "../views/MatricePrixFournisseurs.vue";
import Regulstock from "../views/Regulstock.vue";
import importFournisseur from "../views/importFournisseur.vue";
import HistoriquePlaque from "../views/historiquePlaque.vue";
import CarCatLogin from "../views/carcatLogin.vue";
import Configuration_Filtre from "../views/Configuration_Filtre.vue";
import ConfigurationHub from "../views/ConfigurationHub.vue";
import ConfigurationCatalogue from "../views/ConfigurationCatalogue.vue";
import EntreeSortieStock from "../views/EntreeSortieStock.vue";
import activationFournisseur from "../views/activationFournisseur.vue";
import hubReceptionFournisseur from "../views/hubReceptionFournisseur.vue";
import receptionFournisseur from "../views/receptionFournisseur.vue";
import choixCommanderFournisseur from "../views/choixCommanderFournisseur.vue";
import choixReceptionnerFournisseur from "../views/choixReceptionnerFournisseur.vue";
import commanderFournisseur from "../views/commanderFournisseur.vue";
import Comptoir from "../views/comptoirClient.vue";
import comptoirSession from "../views/comptoirSession.vue";
import hubTeamChiffre from "../views/Team/hubTeamChiffre.vue";
import teamCommerciaux from "../views/Team/teamCommerciaux.vue";
import etatStock from "../views/etatStock.vue";
import inventaireStock from "../views/inventaireStock.vue";
import inventaireRecap from "../views/inventaireRecap.vue";
import hubInventaireStock from "../views/hubInventaireStock.vue";
import setActionClient from "../views/setActionClient.vue";
import detailFournisseur from "../views/detailFournisseur.vue";
import creerFournisseur from "../views/creerFournisseur.vue";
import detailClient from "../views/detailClient.vue";
import creerClient from "../views/creerClient.vue";
import favoriClient from "../views/favoriClient.vue";
import conditionFournisseur from "../views/conditionFournisseur.vue";
import configurationBrandFilter from "../views/configurationBrandFilter.vue";
import configurationAssemblyGroup from "../views/configurationAssemblyGroup.vue";
import catalogueHub from "../views/Catalogue/catalogueHub.vue";
import catalogueTecDoc from "../views/Catalogue/catalogueTecDoc.vue";
import liaisonsDasilva from "../views/liaisonsDasilva.vue";
import specificConditionFournisseur from "../views/specificConditionFournisseur.vue";
import prixNetFournisseur from "../views/prixNetFournisseur.vue";
import paniersClient from "../views/paniersClient.vue";
import detailPanierClient from "../views/detailPanierClient.vue";
import specificConditionFournisseurClient from "../views/specificConditionFournisseurClient.vue";
import prixNetClient from "../views/prixNetClient.vue";
import configurationConnexion from "../views/Configuration_Connexion.vue";
import commandeTecCom from "../views/commandeTecCom.vue";
import ChiffresClient from "../views/ChiffresClient.vue";
import ChiffresFournisseur from "../views/ChiffresFournisseur.vue";
import StatsBl from "../views/StatsBl.vue";
import StatsFactures from "../views/StatsFactures.vue";
import StatsTopArticles from "../views/StatsTopArticles.vue";
import StatsAlertStock from "../views/StatsAlertStock.vue";
import stockToOrder from "../views/stockToOrder.vue";
import HelpPage from "../views/helpPage.vue";
import SetUtilisateur from "../views/SetUtilisateur.vue";
import UtilisateursClient from "../views/UtilisateursClient.vue";
import ImportPneuFournisseur from "../views/ImportPneuFournisseur.vue";
import ImportArticleFournisseur from "../views/ImportArticleFournisseur.vue";
import StatsCommandes from "../views/StatsCommandes.vue";
import SearchArticle from "../views/SearchArticle.vue";
import ConfigurationFiltrePneu from "../views/Configuration_Filtre_Pneu.vue";
import SetArticles from "../views/SetArticles.vue";
import StockArticle from "../views/StockArticle.vue";
import ArticleHub from "../views/ArticleHub.vue";
import PriorityFournisseur from "../views/PriorityFournisseur.vue";
import configurationcataloguetest from "../views/ConfigurationCatalogueTest.vue";
import OrganisationsPage from "../views/Organisations.vue";
import TransfertArchived from "../views/TransfertArchived.vue";
import ImportConditionFournisseur from "../views/ImportConditionFournisseur.vue";
import commandeInstFournisseur from "../views/commandeInstFournisseur.vue";
import prepareCommandeFournisseur from "../views/prepareCommandeFournisseur.vue";
import initInventaire from "../views/initInventaire.vue";
import PrixAchat from "../views/PrixAchat.vue";
import MargeArticle from "../views/MargeArticle.vue";
import CrossBrand from "../views/CrossBrand.vue";
import ManyCrossBrand from "../views/ManyCrossBrand.vue";
import StatsRecherches from "../views/StatsRecherches.vue";
import detailSavedSearch from "../views/detailSavedSearch.vue";
import searchArticleNew from "../views/searchArticleNew.vue";
import searchArticleExact from "../views/searchArticleExact.vue";
import detailBdl from "../views/detailBdl.vue";
import detailDevis from "../views/detailDevis.vue";
import detailFacture from "../views/detailFacture.vue";
import detailFacturation from "../views/detailFacturation.vue";
import detailCommandes from "../views/Clients/detailCommandes.vue";
import Transporteur from "../views/Transport/Transporteur.vue";
import detailTransporteur from "../views/Transport/detailTransporteur.vue";
import creerTransporteur from "../views/Transport/creerTransporteur.vue";
import grilleTransporteur from "../views/Transport/grilleTransporteur.vue";
import regrouperCommandePro from "../views/regrouperCommandePro.vue";
import groupedCmdPro from "../views/Clients/groupedCmdPro.vue";
import regroupement from "../views/Clients/regroupement.vue";
import configurationCatalogueHub from "../views/configurationCatalogueHub.vue";
import configurationFamilleCatalogue from "../views/configurationFamilleCatalogue.vue";
import configurationFamilleMarqueCatalogue from "../views/configurationFamilleMarqueCatalogue.vue";
import catalogueHubNew from "../views/Catalogue/catalogueHubNew.vue";
import catalogueHubUniversel from "../views/Catalogue/catalogueHubUniversel.vue";
import catalogueTecDocNew from "../views/Catalogue/catalogueTecDocNew.vue";
import catalogueTecDocSousFamille from "../views/Catalogue/catalogueTecDocSousFamille.vue";
import configurationBrandArticles from "../views/configurationBrandArticles.vue";
import searchArticleExactBis from "../views/searchArticleExactBis.vue";
import searchArticleVH from "../views/searchArticleVH.vue";
import Configuration_GenArt from "../views/Configuration_GenArt.vue";
import Configuration_GenArt_Brand from "../views/Configuration_GenArt_Brand.vue";
import HistoriqueArticle from "../views/historiqueArticle.vue";
import ControlePreliminaire from "../views/ControlePreliminaire.vue";
import ControlePreliminaireHub from "../views/ControlePreliminaireHub.vue";
import Configuration_Brand_Priority from "../views/Configuration_Brand_Priority.vue";
import configurationBrandFournisseur from "../views/configurationBrandFournisseur.vue";
import PromoMosaique from "../views/PromoMosaique.vue";
import AlertCondition from "../views/AlertCondition.vue";
import ConfigurationCondition from "../views/ConfigurationCondition.vue";
import TableauRemise from "../views/TableauRemise.vue";
import searchArticleDetail from "../views/searchArticleDetail.vue";
import searchArticleDetailBis from "../views/searchArticleDetailBis.vue";
import searchArticleDetailBisForceOE from "../views/searchArticleDetailBisForceOE.vue";
import PropalBrandPriority from "../views/PropalBrandPriority.vue";
import CrossArticle from "../views/CrossArticle.vue";
import transfertHub from "../views/transfertHub.vue";
import transfertStock from "../views/transfertStock.vue";
import transfertInstantane from "../views/transfertInstantane.vue";

const routes = [
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
    meta: { layout: "empty" },
  },
  {
    path: "/PromoMosaique",
    name: "PromoMosaique",
    component: PromoMosaique,
  },
  {
    path: "/PropalBrandPriority",
    name: "PropalBrandPriority",
    component: PropalBrandPriority,
  },
  {
    path: "/ControlePreliminaireHub",
    name: "ControlePreliminaireHub",
    component: ControlePreliminaireHub,
  },
  {
    path: "/ControlePreliminaire",
    name: "ControlePreliminaire",
    component: ControlePreliminaire,
  },
  {
    path: "/ConfigurationCondition",
    name: "ConfigurationCondition",
    component: ConfigurationCondition,
  },
  {
    path: "/searchArticleDetailBisForceOE",
    name: "searchArticleDetailBisForceOE",
    component: searchArticleDetailBisForceOE,
  },
  {
    path: "/CrossArticle",
    name: "CrossArticle",
    component: CrossArticle,
  },
  {
    path: "/TableauRemise",
    name: "TableauRemise",
    component: TableauRemise,
  },
  {
    path: "/ConfigurationGenArtBrand",
    name: "ConfigurationGenArtBrand",
    component: Configuration_GenArt_Brand,
  },
  {
    path: "/AlertCondition",
    name: "AlertCondition",
    component: AlertCondition,
  },
  {
    path: "/ConfigurationGenArt",
    name: "ConfigurationGenArt",
    component: Configuration_GenArt,
  },
  {
    path: "/ConfigurationBrandPriority",
    name: "ConfigurationBrandPriority",
    component: Configuration_Brand_Priority,
  },
  {
    path: "/ConfigurationBrandFournisseur",
    name: "ConfigurationBrandFournisseur",
    component: configurationBrandFournisseur,
  },
  {
    path: "/searchArticleVH",
    name: "searchArticleVH",
    component: searchArticleVH,
  },
  {
    path: "/searchArticleDetail",
    name: "searchArticleDetail",
    component: searchArticleDetail,
  },
  {
    path: "/searchArticleDetailBis",
    name: "searchArticleDetailBis",
    component: searchArticleDetailBis,
  },
  {
    path: "/searchArticleExactBis",
    name: "searchArticleExactBis",
    component: searchArticleExactBis,
  },
  {
    path: "/configurationBrandArticles",
    name: "configurationBrandArticles",
    component: configurationBrandArticles,
  },
  {
    path: "/searchArticleNew",
    name: "searchArticleNew",
    component: searchArticleNew,
  },
  {
    path: "/searchArticleExact",
    name: "searchArticleExact",
    component: searchArticleExact,
  },
  {
    path: "/configurationBrandFilter",
    name: "configurationBrandFilter",
    component: configurationBrandFilter,
  },
  {
    path: "/catalogueHubNew",
    name: "catalogueHubNew",
    component: catalogueHubNew,
  },
  {
    path: "/catalogueHubUniversel",
    name: "catalogueHubUniversel",
    component: catalogueHubUniversel,
  },
  {
    path: "/catalogueTecDocNew",
    name: "catalogueTecDocNew",
    component: catalogueTecDocNew,
  },
  {
    path: "/catalogueTecDocSousFamille",
    name: "catalogueTecDocSousFamille",
    component: catalogueTecDocSousFamille,
  },
  {
    path: "/configurationCatalogueHub",
    name: "configurationCatalogueHub",
    component: configurationCatalogueHub,
  },
  {
    path: "/configurationFamilleCatalogue",
    name: "configurationFamilleCatalogue",
    component: configurationFamilleCatalogue,
  },
  {
    path: "/configurationFamilleMarqueCatalogue",
    name: "configurationFamilleMarqueCatalogue",
    component: configurationFamilleMarqueCatalogue,
  },
  {
    path: "/detailBdl",
    name: "detailBdl",
    component: detailBdl,
  },
  {
    path: "/detailDevis",
    name: "detailDevis",
    component: detailDevis,
  },
  {
    path: "/detailCommandes",
    name: "detailCommandes",
    component: detailCommandes,
  },
  {
    path: "/detailFacture",
    name: "detailFacture",
    component: detailFacture,
  },
  {
    path: "/detailFacturation",
    name: "detailFacturation",
    component: detailFacturation,
  },
  {
    path: "/configurationConnexion",
    name: "configurationConnexion",
    component: configurationConnexion,
  },
  {
    path: "/commandeTecCom",
    name: "commandeTecCom",
    component: commandeTecCom,
  },
  {
    path: "/prepareCommandeFournisseur",
    name: "prepareCommandeFournisseur",
    component: prepareCommandeFournisseur,
  },
  {
    path: "/configurationFiltrePneu",
    name: "ConfigurationFiltrePneu",
    component: ConfigurationFiltrePneu,
  },
  {
    path: "/commandeInstFournisseur",
    name: "commandeInstFournisseur",
    component: commandeInstFournisseur,
  },
  {
    path: "/detailSavedSearch",
    name: "detailSavedSearch",
    component: detailSavedSearch,
  },
  {
    path: "/catalogueTecDoc",
    name: "catalogueTecDoc",
    component: catalogueTecDoc,
  },
  {
    path: "/catalogueHub",
    name: "catalogueHub",
    component: catalogueHub,
  },
  {
    path: "/searchArticle",
    name: "SearchArticle",
    component: SearchArticle,
  },
  {
    path: "/PrixAchat",
    name: "PrixAchat",
    component: PrixAchat,
  },
  {
    path: "/configurationAssemblyGroup",
    name: "configurationAssemblyGroup",
    component: configurationAssemblyGroup,
  },
  {
    path: "/configurationcataloguetest",
    name: "configurationcataloguetest",
    component: configurationcataloguetest,
  },
  {
    path: "/comptoirClient",
    name: "Comptoir",
    component: Comptoir,
  },
  {
    path: "/PriorityFournisseur",
    name: "PriorityFournisseur",
    component: PriorityFournisseur,
  },
  {
    path: "/ImportConditionFournisseur",
    name: "ImportConditionFournisseur",
    component: ImportConditionFournisseur,
  },
  {
    path: "/setActionClient",
    name: "setActionClient",
    component: setActionClient,
  },
  {
    path: "/comptoirSession",
    name: "comptoirSession",
    component: comptoirSession,
  },
  {
    path: "/specificConditionFournisseur",
    name: "specificConditionFournisseur",
    component: specificConditionFournisseur,
  },
  {
    path: "/ImportArticleFournisseur",
    name: "ImportArticleFournisseur",
    component: ImportArticleFournisseur,
  },
  {
    path: "/CrossBrand",
    name: "CrossBrand",
    component: CrossBrand,
  },
  {
    path: "/ManyCrossBrand",
    name: "ManyCrossBrand",
    component: ManyCrossBrand,
  },
  {
    path: "/ImportPneuFournisseur",
    name: "ImportPneuFournisseur",
    component: ImportPneuFournisseur,
  },
  {
    path: "/prixNetFournisseur",
    name: "prixNetFournisseur",
    component: prixNetFournisseur,
  },
  {
    path: "/StatsRecherches",
    name: "StatsRecherches",
    component: StatsRecherches,
  },
  {
    path: "/prixNetClient",
    name: "prixNetClient",
    component: prixNetClient,
  },
  {
    path: "/paniersClient",
    name: "paniersClient",
    component: paniersClient,
  },
  {
    path: "/detailPanierClient",
    name: "detailPanierClient",
    component: detailPanierClient,
  },
  {
    path: "/specificConditionFournisseurClient",
    name: "specificConditionFournisseurClient",
    component: specificConditionFournisseurClient,
  },
  {
    path: "/ChiffresClient",
    name: "ChiffresClient",
    component: ChiffresClient,
  },
  {
    path: "/stockToOrder",
    name: "stockToOrder",
    component: stockToOrder,
  },
  {
    path: "/profil",
    name: "Profil",
    component: Profil,
  },
  {
    path: "/conditionFournisseur",
    name: "conditionFournisseur",
    component: conditionFournisseur,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/articles",
    name: "Articles",
    component: Articles,
  },
  {
    path: "/fournisseurs",
    name: "Fournisseurs",
    component: Fournisseurs,
  },
  {
    path: "/preparation",
    name: "Preparation",
    component: Preparation,
  },
  {
    path: "/commandePro",
    name: "commandePro",
    component: commandePro,
  },
  {
    path: "/teamCommerciaux",
    name: "teamCommerciaux",
    component: teamCommerciaux,
  },
  {
    path: "/margeArticle",
    name: "MargeArticle",
    component: MargeArticle,
  },
  {
    path: "/enlevement",
    name: "Enlevement",
    component: Enlevement,
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
  },
  {
    path: "/precommande",
    name: "Precommande",
    component: Precommande,
  },
  {
    path: "/statistiques",
    name: "Statistiques",
    component: Statistiques,
  },
  {
    path: "/base",
    name: "Base",
    component: Base,
  },
  {
    path: "/hubTeamChiffre",
    name: "hubTeamChiffre",
    component: hubTeamChiffre,
  },
  {
    path: "/caisse",
    name: "Caisse",
    component: Caisse,
  },
  {
    path: "/historiqueCaisse",
    name: "historiqueCaisse",
    component: historiqueCaisse,
  },
  {
    path: "/encaissement",
    name: "Encaissement",
    component: Encaissement,
  },
  {
    path: "/etatStock",
    name: "etatStock",
    component: etatStock,
  },
  {
    path: "/inventaireStock",
    name: "inventaireStock",
    component: inventaireStock,
  },
  {
    path: "/inventaireRecap",
    name: "inventaireRecap",
    component: inventaireRecap,
  },
  {
    path: "/hubInventaireStock",
    name: "hubInventaireStock",
    component: hubInventaireStock,
  },
  {
    path: "/utilisateurs",
    name: "Utilisateurs",
    component: Utilisateurs,
  },
  {
    path: "/SetUtilisateur/:userID",
    name: "SetUtilisateur",
    component: SetUtilisateur,
  },
  {
    path: "/UtilisateursClient",
    name: "UtilisateursClient",
    component: UtilisateursClient,
  },
  {
    path: "/promotions",
    name: "Promotions",
    component: Promotions,
  },
  {
    path: "/mouvementstock",
    name: "MouvementStock",
    component: MouvementStock,
  },
  {
    path: "/actionclient",
    name: "actionClient",
    component: actionClient,
  },
  {
    path: "/famillearticle",
    name: "FamilleArticle",
    component: FamilleArticle,
  },
  {
    path: "/tecdoc",
    name: "TecDoc",
    component: TecDoc,
  },
  {
    path: "/tecdocsearch",
    name: "TecDocSearch",
    component: TecDocSearch,
  },
  {
    path: "/carcat",
    name: "CarCat",
    component: CarCat,
  },
  {
    path: "/cataloguedepneus",
    name: "CatalogueDePneus",
    component: CatalogueDePneus,
  },
  {
    path: "/sousfamillearticle",
    name: "SousFamilleArticle",
    component: SousFamilleArticle,
  },
  {
    path: "/devis",
    name: "Devis",
    component: Devis,
  },

  {
    path: "/facture",
    name: "Facture",
    component: Facture,
  },
  {
    path: "/commandes",
    name: "Commandes",
    component: Commandes,
  },
  {
    path: "/bdl",
    name: "Bdl",
    component: Bdl,
  },
  {
    path: "/deletedBdl",
    name: "deletedBdl",
    component: deletedBdl,
  },
  {
    path: "/bdt",
    name: "Bdt",
    component: Bdt,
  },
  {
    path: "/groupebdl",
    name: "GroupeBdl",
    component: GroupeBdl,
  },
  {
    path: "/consigne",
    name: "Consigne",
    component: Consigne,
  },
  {
    path: "/retour",
    name: "Retour",
    component: Retour,
  },
  {
    path: "/detailRetour",
    name: "detailRetour",
    component: detailRetour,
  },
  {
    path: "/avoir",
    name: "Avoir",
    component: Avoir,
  },
  {
    path: "/facture",
    name: "Facture",
    component: Facture,
  },
  {
    path: "/facturation",
    name: "Facturation",
    component: Facturation,
  },
  {
    path: "/depot",
    name: "Depot",
    component: Depot,
  },
  {
    path: "/matriceprix",
    name: "MatricePrix",
    component: MatricePrix,
  },
  {
    path: "/regulstock",
    name: "Regulstock",
    component: Regulstock,
  },
  {
    path: "/importfournisseur",
    name: "importFournisseur",
    component: importFournisseur,
  },
  {
    path: "/historiqueplaque",
    name: "HistoriquePlaque",
    component: HistoriquePlaque,
  },
  {
    path: "/historiqueArticle",
    name: "HistoriqueArticle",
    component: HistoriqueArticle,
  },
  {
    path: "/carcatlogin",
    name: "CarCatLogin",
    component: CarCatLogin,
  },
  {
    path: "/configurationFiltre",
    name: "Configuration_Filtre",
    component: Configuration_Filtre,
  },
  {
    path: "/entreesortiestock",
    name: "EntreeSortieStock",
    component: EntreeSortieStock,
  },
  {
    path: "/configurationhub",
    name: "ConfigurationHub",
    component: ConfigurationHub,
  },
  {
    path: "/configurationcatalogue",
    name: "ConfigurationCatalogue",
    component: ConfigurationCatalogue,
  },
  {
    path: "/activationFournisseur",
    name: activationFournisseur,
    component: activationFournisseur,
  },
  {
    path: "/hubReceptionFournisseur",
    name: hubReceptionFournisseur,
    component: hubReceptionFournisseur,
  },
  {
    path: "/receptionFournisseur",
    name: receptionFournisseur,
    component: receptionFournisseur,
  },
  {
    path: "/choixCommanderFournisseur",
    name: choixCommanderFournisseur,
    component: choixCommanderFournisseur,
  },
  {
    path: "/choixReceptionnerFournisseur",
    name: choixReceptionnerFournisseur,
    component: choixReceptionnerFournisseur,
  },
  {
    path: "/commanderFournisseur",
    name: commanderFournisseur,
    component: commanderFournisseur,
  },
  {
    path: "/detailFournisseur",
    name: detailFournisseur,
    component: detailFournisseur,
  },
  {
    path: "/creerFournisseur",
    name: creerFournisseur,
    component: creerFournisseur,
  },
  {
    path: "/detailClient",
    name: detailClient,
    component: detailClient,
  },
  {
    path: "/creerClient",
    name: creerClient,
    component: creerClient,
  },
  {
    path: "/favoriClient",
    name: favoriClient,
    component: favoriClient,
  },
  {
    path: "/liaisonsDasilva",
    name: liaisonsDasilva,
    component: liaisonsDasilva,
  },
  {
    path: "/ChiffresFournisseur",
    name: ChiffresFournisseur,
    component: ChiffresFournisseur,
  },
  {
    path: "/StatsBl",
    name: StatsBl,
    component: StatsBl,
  },
  {
    path: "/StatsFactures",
    name: StatsFactures,
    component: StatsFactures,
  },
  {
    path: "/StatsTopArticles",
    name: StatsTopArticles,
    component: StatsTopArticles,
  },
  {
    path: "/StatsAlertStock",
    name: StatsAlertStock,
    component: StatsAlertStock,
  },
  {
    path: "/helpPage",
    name: HelpPage,
    component: HelpPage,
  },
  {
    path: "/StatsCommandes",
    name: StatsCommandes,
    component: StatsCommandes,
  },
  {
    path: "/SetArticles",
    name: "SetArticles",
    component: SetArticles,
  },
  {
    path: "/StockArticle",
    name: "StockArticle",
    component: StockArticle,
  },
  {
    path: "/articleHub",
    name: "ArticleHub",
    component: ArticleHub,
  },
  {
    path: "/Organisations",
    name: "OrganisationsPage",
    component: OrganisationsPage,
  },
  {
    path: "/transfertarchive",
    name: "TransfertArchived",
    component: TransfertArchived,
  },
  { path: "/:pathMatch(.*)*", component: Dashboard },
  {
    path: "/initInventaire",
    name: "initInventaire",
    component: initInventaire,
  },
  {
    path: "/Transporteur",
    name: "Transporteur",
    component: Transporteur,
  },
  {
    path: "/detailTransporteur",
    name: "detailTransporteur",
    component: detailTransporteur,
  },
  {
    path: "/creerTransporteur",
    name: "creerTransporteur",
    component: creerTransporteur,
  },
  {
    path: "/grilleTransporteur",
    name: "grilleTransporteur",
    component: grilleTransporteur,
  },
  {
    path: "/regrouperCommandePro",
    name: "regrouperCommandePro",
    component: regrouperCommandePro,
  },
  {
    path: "/groupedCmdPro",
    name: "groupedCmdPro",
    component: groupedCmdPro,
  },
  {
    path: "/regroupement",
    name: "regroupement",
    component: regroupement,
  },
  {
    path: "/transfertHub",
    name: "transfertHub",
    component: transfertHub,
  },
  {
    path: "/transfertStock",
    name: "transfertStock",
    component: transfertStock,
  },
  {
    path: "/transfertInstantane",
    name: "transfertInstantane",
    component: transfertInstantane,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      store.dispatch("setUser", user.uid);
      next();
    } else {
      if (to.path !== "/login") {
        store.dispatch("deconnecter");
        next({ name: "LoginPage" });
      } else {
        next();
      }
    }
  });
});

export default router;
