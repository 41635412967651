<template>
  <div>
    <div class="flex flex-row items-center space-x-8">
      <router-link to="/articlehub">
        <div id="retour" class="flex justify-start w-fit">
          <backButton />
        </div>
      </router-link>
      <div id="Recherche" class="w-96">
        <label
          for="default-search"
          class="mb-2 text-sm font-medium text-gray-900 sr-only"
          >Search</label
        >
        <div class="relative">
          <div
            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            type="text"
            id="default-search"
            placeholder="Rechercher par dimensions"
            v-model="inputSearchPneu"
            @keyup.enter="searchPneu()"
            class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-sky-300 outline-2"
            required
          />
        </div>
      </div>
      <div>
        <div
          class="relative cursor-pointer p-2 bg-blue-500 text-white rounded"
          @click="showType = !showType"
        >
          Type de Véhicule
        </div>
        <div
          v-if="showType"
          class="absolute z-20 p-2 bg-white rounded shadow flex flex-col space-y-2 h-64 overflow-y-auto"
        >
          <div>
            <div class="flex flex-row items-center space-x-2">
              <input type="checkbox" id="U" value="U" v-model="checkedType" />
              <label for="U">U</label>
            </div>
            <div class="flex flex-row items-center space-x-2">
              <input type="checkbox" id="VL" value="VL" v-model="checkedType" />
              <label for="VL">VL</label>
            </div>
            <div class="flex flex-row items-center space-x-2">
              <input type="checkbox" id="PL" value="PL" v-model="checkedType" />
              <label for="PL">PL</label>
            </div>
            <div class="flex flex-row items-center space-x-2">
              <input
                type="checkbox"
                id="MOTO"
                value="MOTO"
                v-model="checkedType"
              />
              <label for="MOTO">MOTO</label>
            </div>
            <div class="flex flex-row items-center space-x-2">
              <input
                type="checkbox"
                id="AGRI"
                value="AGRI"
                v-model="checkedType"
              />
              <label for="AGRI">AGRI</label>
            </div>
            <div class="flex flex-row items-center space-x-2">
              <input
                type="checkbox"
                id="4X4"
                value="4X4"
                v-model="checkedType"
              />
              <label for="4X4">4X4</label>
            </div>
            <div class="flex flex-row items-center space-x-2">
              <input
                type="checkbox"
                id="COMPETITION"
                value="COMPETITION"
                v-model="checkedType"
              />
              <label for="COMPETITION">COMPETITION</label>
            </div>
            <div class="flex flex-row items-center space-x-2">
              <input
                type="checkbox"
                id="COLLECTION"
                value="COLLECTION"
                v-model="checkedType"
              />
              <label for="COLLECTION">COLLECTION</label>
            </div>
            <div class="flex flex-row items-center space-x-2">
              <input
                type="checkbox"
                id="SUV"
                value="SUV"
                v-model="checkedType"
              />
              <label for="SUV">SUV</label>
            </div>
          </div>
        </div>
      </div>
      <div>
        <select
          @change="searchPneu()"
          v-model="pneuPerPage"
          class="p-2 rounded-md"
        >
          <option :value="10">10</option>
          <option :value="20">20</option>
          <option :value="50">50</option>
          <option :value="100">100</option>
        </select>
      </div>
      <div class="flex flex-row items-center space-x-80">
        <div>
          <button
            @click="searchPneu()"
            class="text-white bg-sky-600 hover:bg-sky-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
          >
            Rechercher
          </button>
        </div>
        <div></div>
        <button
          @click="toggleAllCheckboxes"
          class="text-blue-500 hover:text-blue-400 underline [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)] font-medium text-sm"
        >
          Tout sélect. / désélect.
        </button>
      </div>
    </div>

    <div>
      <div class="p-2 grid grid-cols-9 text-xs">
        <div v-for="(bd, indexBD) in brandPneuList" :key="indexBD">
          <div class="flex flex-row items-center space-x-2">
            <input
              type="checkbox"
              :id="indexBD"
              :value="bd"
              v-model="checkedMarques"
            />
            <label :for="indexBD">{{ bd }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row space-x-4 mt-4" v-if="searchedState">
      <div class="col-md-3 space-y-4 bg-white p-2 rounded-md shadow-md">
        <div class="mb-6">
          <h5 class="font-bold text-xl">Filtres</h5>
        </div>

        <div id="prix" class="flex flex-col space-y-2">
          <h5 class="font-bold mb-1">Prix</h5>
          <div class="flex flex-row items-center space-x-2">
            <span
              class="p-1 bg-blue-500 text-white rounded shadow cursor-pointer"
              @click="sortCroissant()"
              >Croissant</span
            >
            <span
              class="p-1 bg-blue-500 text-white rounded shadow cursor-pointer"
              @click="sortDecroissant()"
              >Decroissant</span
            >
          </div>
          <div class="flex space-x-4">
            <input
              type="number"
              placeholder="min"
              min="0"
              v-model="minPrice"
              @change="filterMinPrice()"
              class="w-16 border focus:outline-2 outline-sky-300"
            />€
            <input
              type="number"
              placeholder="max"
              min="0"
              v-model="maxPrice"
              @change="filterMaxPrice()"
              class="w-16 border focus:outline-2 outline-sky-300"
            />€
          </div>
        </div>

        <div id="saison">
          <div class="flex flex-row items-center space-x-2">
            <div><h5 class="font-bold mb-1">Saison</h5></div>
          </div>
          <div class="flex flex-col space-y-2">
            <div v-if="saisonTabFilter.includes('ETE')">
              <div
                class="flex flex-row items-center bg-blue-500 text-white space-x-1 border border-gray-200 rounded px-2 py-1 w-fit cursor-pointer hover:bg-gray-100"
                @click="filterSaison('ETE')"
                v-if="saisonTab.includes('ETE')"
              >
                <img src="../../assets/icon/ete.png" class="w-6 h-6" />
                <span>Ete</span>
              </div>
              <div
                class="flex flex-row items-center space-x-1 border border-gray-200 rounded px-2 py-1 w-fit cursor-pointer hover:bg-gray-100"
                @click="filterSaison('ETE')"
                v-else
              >
                <img src="../../assets/icon/ete.png" class="w-6 h-6" />
                <span>Ete</span>
              </div>
            </div>
            <div v-if="saisonTabFilter.includes('4S')">
              <div
                class="flex flex-row items-center bg-blue-500 text-white space-x-1 border border-gray-200 rounded px-2 py-1 w-fit cursor-pointer hover:bg-gray-100"
                @click="filterSaison('4S')"
                v-if="saisonTab.includes('4S')"
              >
                <img src="../../assets/icon/saison.png" class="w-6 h-6" />
                <span>4 saisons</span>
              </div>
              <div
                class="flex flex-row items-center space-x-1 border border-gray-200 rounded px-2 py-1 w-fit cursor-pointer hover:bg-gray-100"
                @click="filterSaison('4S')"
                v-else
              >
                <img src="../../assets/icon/saison.png" class="w-6 h-6" />
                <span>4 saisons</span>
              </div>
            </div>
            <div v-if="saisonTabFilter.includes('HIVER')">
              <div
                class="flex flex-row items-center bg-blue-500 text-white space-x-1 border border-gray-200 rounded px-2 py-1 w-fit cursor-pointer hover:bg-gray-100"
                @click="filterSaison('HIVER')"
                v-if="saisonTab.includes('HIVER')"
              >
                <img src="../../assets/icon/hiver.png" class="w-6 h-6" />
                <span>Hiver</span>
              </div>
              <div
                class="flex flex-row items-center space-x-1 border border-gray-200 rounded px-2 py-1 w-fit cursor-pointer hover:bg-gray-100"
                @click="filterSaison('HIVER')"
                v-else
              >
                <img src="../../assets/icon/hiver.png" class="w-6 h-6" />
                <span>Hiver</span>
              </div>
            </div>
          </div>
        </div>

        <div id="indicedecharge">
          <h5 class="font-bold mb-1">Indice de charge</h5>
          <div class="grid grid-cols-3 gap-2">
            <div
              v-for="(indice, indexIndice) in indiceCharge"
              :key="indexIndice"
              @click="filterIndiceCharge(indice)"
            >
              <span
                v-if="indiceChargeTab.includes(indice)"
                class="bg-blue-500 text-white border border-gray-200 rounded px-2 py-1 w-fit cursor-pointer hover:bg-gray-100"
                >{{ indice }}</span
              >
              <span
                v-else
                class="border border-gray-200 rounded px-2 py-1 w-fit cursor-pointer hover:bg-gray-100"
                >{{ indice }}</span
              >
            </div>
          </div>
        </div>

        <div id="indicedevitesse">
          <h5 class="font-bold mb-1">Indice de vitesse</h5>
          <div class="grid grid-cols-3 gap-2">
            <div
              v-for="(ivPn, indexIV) in iv"
              :key="indexIV"
              @click="filterIV(ivPn)"
            >
              <span
                v-if="ivTab.includes(ivPn)"
                class="bg-blue-500 text-white border border-gray-200 rounded px-2 py-1 w-fit cursor-pointer hover:bg-gray-100"
                >{{ ivPn }}</span
              >
              <span
                v-else
                class="border border-gray-200 rounded px-2 py-1 w-fit cursor-pointer hover:bg-gray-100"
                >{{ ivPn }}</span
              >
            </div>
          </div>
        </div>

        <div id="runflat">
          <div class="flex flex-row items-center space-x-2">
            <span> Runflat </span>
            <label class="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                class="sr-only peer"
                v-model="runflat"
                @change="showRunflat()"
              />
              <div
                class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
              ></div>
            </label>
          </div>
        </div>
      </div>

      <!--Catalogue-->
      <div
        class="w-full space-y-4 overflow-y-auto h-80"
        v-if="pneu.length == 0"
      >
        Veuillez remplir les champs de recherche pour trouver des pneus
      </div>
      <div v-if="loadingPneu" v-show="loadingPneu" class="m-auto w-full h-full">
        <img class="m-auto" src="../../assets/LoadingGIF/tyregif.gif" />
        <!-- <span class="mx-auto">Chargement...</span> -->
      </div>
      <div class="w-full space-y-4 overflow-y-auto h-80">
        <div
          class="flex items-center px-2 py-2 bg-white rounded-md shadow-sm"
          v-for="(pn, indexPn) in pneu"
          :key="indexPn"
        >
          <div class="p-1 w-3/12">
            <div class="flex flex-row items-center space-x-6">
              <img class="h-24 w-18" src="../../assets/pneus.jpg" />
              <div class="flex flex-col space-y-1">
                <h4 class="font-bold">{{ pn.Ref_fournisseur }}</h4>
                <div class="flex flex-row items-center space-x-1">
                  <img
                    v-if="pn.saison_pneu.toUpperCase() == 'ETE'"
                    src="../../assets/icon/ete.png"
                    class="h-6 w-6"
                  />
                  <img
                    v-if="pn.saison_pneu.toUpperCase() == 'HIVER'"
                    src="../../assets/icon/hiver.png"
                    class="h-6 w-6"
                  />
                  <img
                    v-if="pn.saison_pneu.toUpperCase() == '4S'"
                    src="../../assets/icon/saison.png"
                    class="h-6 w-6"
                  />
                </div>

                <div class="flex flex-row items-center space-x-2">
                  <div class="flex flex-row items-center space-x-1">
                    <img src="../../assets/icon/gas.png" class="h-4 w-4" />
                    <span class="text-gray-500">{{
                      pn.resistence_roulement_pneu
                    }}</span>
                  </div>
                  <div class="flex flex-row items-center space-x-1">
                    <img src="../../assets/icon/pluie.png" class="h-4 w-4" />
                    <span class="text-gray-500">{{
                      pn.freinage_mouille_pneu
                    }}</span>
                  </div>
                  <div class="flex flex-row items-center space-x-1">
                    <img src="../../assets/icon/sound.png" class="h-4 w-4" />
                    <span class="text-gray-500">{{ pn.dB_pneu }}</span>
                  </div>
                </div>
                <div class="relative">
                  <span
                    class="text-gray-500"
                    @mouseenter="eprelDiv[indexPn] = true"
                    @mouseleave="eprelDiv[indexPn] = false"
                    ><a
                      class="text-blue-600 flex flex-row items-center space-x-1"
                      :href="pn.url_eprel_pneu"
                      target="_blank"
                    >
                      <span class="material-icons-round">
                        qr_code_scanner
                      </span>
                      <span>eprel</span>
                    </a></span
                  >
                  <div
                    v-if="pn.url_eprel_pneu && eprelDiv[indexPn]"
                    v-show="eprelDiv[indexPn]"
                    class="absolute w-56 h-82 p-2 bg-white rounded shadow z-20"
                  >
                    <img
                      v-if="pn.url_eprel_pneu"
                      :src="
                        'https://eprel.ec.europa.eu/label/Label_' +
                        pn.url_eprel_pneu.substr(30, 7) +
                        '.svg'
                      "
                      alt="eprel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-4/12">
            <div class="text-lg font-bold" v-if="pn.Code_marque">
              {{ pn.Code_marque }}
            </div>
            <div class="text-lg font-bold" v-else>{{ pn.marque_pneu }}</div>
            <div class="text-gray-500">{{ pn.Description }}</div>
          </div>

          <div class="w-2/12">
            <!-- <infoDispoEANArticleVue
              :Code_EAN="pn.Code_EAN"
              :qty="pn.qty"
              :key="componentKey[indexPn]"
            /> -->
            <stockvue
              :Article="pn"
              :quantity="pn.qty"
              :key="pn._id + pn.qty.toString()"
            />
          </div>
          <div class="w-2/12 flex flex-col space-y-2">
            <span class="flex flex-row items-center space-x-1">
              <prixNetArticleVue
                :article="pn.Ref_fournisseur"
                :marque="pn.Code_marque"
                :Prefixe_tarif="pn.Prefixe_tarif"
                :qty="pn.qty"
                :key="pn._id + pn.qty.toString()"
            /></span>
          </div>
          <div class="w-3/12 flex flex-col space-y-2">
            <div class="flex flex-row items-center mx-auto">
              <span
                class="material-icons-round cursor-pointer"
                @click="pn.qty = pn.qty - 1"
              >
                remove_circle_outline
              </span>
              <input
                v-model="pn.qty"
                type="number"
                class="w-16 appearance-none text-center"
              />
              <span
                class="material-icons-round cursor-pointer"
                @click="pn.qty = pn.qty + 1"
              >
                add_circle_outline
              </span>
            </div>
            <div class="mx-auto">
              <button
                class="flex py-4 rounded-md shadow-sm py-1 px-2 content bg-blue-200"
                @click="handlerArticle({ article: pn, stockData: [] }, pn.qty)"
              >
                <span class="material-icons-round cursor-pointer">
                  add_shopping_cart
                </span>
                Ajouter au panier
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { useToast } from "vue-toastification";
// import infoDispoEANArticleVue from "@/components/articleInfo/infoDispoEANArticle.vue";
import prixNetArticleVue from "@/components/Catalogue/prixNetArticle.vue";
import stockvue from "@/components/Article/stock.vue";
import { searchArticles, prepareArticle } from "@/hooks/searchEngine/articles";
import backButton from "@/components/Button/back.vue";

export default {
  name: "MyCatalogueDepneus",
  data() {
    return {
      pneu: [],
      pneuSave: [],
      pneuSaveRunflat: [],
      loadingPneu: false,
      componentKey: [],
      eprelDiv: [],
      inputSearchPneu: "205",
      pneuPerPage: 100,
      minPrice: 0,
      maxPrice: 0,
      indiceCharge: [],
      saisonTab: [],
      indiceChargeTab: [],
      saisonTabFilter: [],
      iv: [],
      ivTab: [],
      runflat: false,
      vehiculeTab: [],
      brandPneuList: [],
      showType: false,
      checkedMarques: [],
      checkedType: [
        "U",
        "VL",
        "PL",
        "MOTO",
        "AGRI",
        "4X4",
        "COMPETITION",
        "COLLECTION",
        "SUV",
      ],
      searchedState: false,
    };
  },
  components: {
    // infoDispoEANArticleVue,
    prixNetArticleVue,
    stockvue,
    backButton,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    ...mapGetters([
      "user",
      "comptoirSessionActive",
      "actionArticle",
      "plateform",
    ]),
  },
  methods: {
    ...mapActions([
      "addactionArticle",
      "adddataconsigne",
      "adddatanonconsigne",
      "savecomptoirsession",
    ]),
    async getPneu() {
      this.loadingPneu = true;
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere_catalogue) {
        plateform_mere = this.plateform.plateform_mere_catalogue;
      }
      await axios
        .post(`${process.env.VUE_APP_API}/article/getpneu`, {
          plateform: plateform_mere,
          limit: this.pneuPerPage,
          checkedMarques: this.checkedMarques,
          checkedType: this.checkedType,
        })
        .then((res) => {
          this.pneu = res.data;
          for (const pn of this.pneu) {
            // pn.Prix_euro = pn.Prix_euro.replace(",", ".");
            pn.qty = 2;
          }
          this.pneuSave = this.pneu;
          this.setAllFilter();
          this.loadingPneu = false;
        });
    },
    searchPneu() {
      this.loadingPneu = true;
      this.showBD = false;
      this.showType = false;
      this.searchedState = true;
      this.pneu = [];
      if (this.inputSearchPneu == "") {
        this.getPneu();
      } else {
        axios
          .post(`${process.env.VUE_APP_API}/article/searchpneu`, {
            plateform: this.user.proprietaire,
            search: this.inputSearchPneu,
            limit: this.pneuPerPage,
            checkedMarques: this.checkedMarques,
            checkedType: this.checkedType,
          })
          .then((res) => {
            this.pneu = res.data;
            for (const pn of this.pneu) {
              // pn.Prix_euro = pn.Prix_euro.replace(",", ".");
              pn.qty = 2;
            }
            this.pneuSave = this.pneu;
            this.setAllFilter();
            this.loadingPneu = false;
          });
      }
    },
    firstSearchPneu() {
      this.loadingPneu = true;
      this.showBD = false;
      this.showType = false;
      this.searchedState = true;
      this.pneu = [];
      if (this.inputSearchPneu == "") {
        this.getPneu();
      } else {
        axios
          .post(`${process.env.VUE_APP_API}/article/searchpneu`, {
            plateform: this.user.proprietaire,
            search: "205",
            limit: this.pneuPerPage,
            checkedMarques: this.checkedMarques,
            checkedType: this.checkedType,
          })
          .then((res) => {
            this.pneu = res.data;
            for (const pn of this.pneu) {
              // pn.Prix_euro = pn.Prix_euro.replace(",", ".");
              pn.qty = 2;
            }
            this.pneuSave = this.pneu;
            this.setAllFilter();
            this.loadingPneu = false;
          });
      }
    },
    filterMinPrice() {
      if (this.minPrice == 0) {
        this.pneu = this.pneuSave;
      } else {
        this.pneu = this.pneuSave.filter((pn) => {
          return pn.Prix_euro >= this.minPrice;
        });
      }
      this.setFilterIndiceCharge();
    },
    filterMaxPrice() {
      if (this.maxPrice == 0) {
        this.pneu = this.pneuSave;
      } else {
        this.pneu = this.pneuSave.filter((pn) => {
          return pn.Prix_euro <= this.maxPrice;
        });
      }
      this.setFilterIndiceCharge();
    },
    setAllFilter() {
      this.setFilterIndiceCharge();
      this.setFilterSaison();
      this.setFilterIV();
    },
    filterSaison(saison) {
      if (!this.saisonTab.includes(saison)) {
        this.saisonTab.push(saison);
      } else {
        this.saisonTab.splice(this.saisonTab.indexOf(saison), 1);
      }

      if (this.saisonTab.length == 0) {
        this.pneu = this.pneuSave;
      } else {
        this.pneu = this.pneuSave.filter((pn) => {
          return this.saisonTab.includes(pn.saison_pneu);
        });
      }
      this.setFilterIndiceCharge();
      this.setFilterIV();
    },
    filterIndiceCharge(charge) {
      if (!this.indiceChargeTab.includes(charge)) {
        this.indiceChargeTab.push(charge);
      } else {
        this.indiceChargeTab.splice(this.indiceChargeTab.indexOf(charge), 1);
      }

      if (this.indiceChargeTab.length == 0) {
        this.pneu = this.pneuSave;
      } else {
        this.pneu = this.pneuSave.filter((pn) => {
          return this.indiceChargeTab.includes(pn.charge_pneu);
        });
      }
      this.setFilterSaison();
      this.setFilterIV();
    },
    filterIV(ivPneu) {
      if (!this.ivTab.includes(ivPneu)) {
        this.ivTab.push(ivPneu);
      } else {
        this.ivTab.splice(this.ivTab.indexOf(ivPneu), 1);
      }

      if (this.ivTab.length == 0) {
        this.pneu = this.pneuSave;
      } else {
        this.pneu = this.pneuSave.filter((pn) => {
          return this.ivTab.includes(pn.iv_pneu);
        });
      }
      this.setFilterSaison();
      this.setFilterIndiceCharge();
    },
    setFilterIndiceCharge() {
      this.indiceCharge = [];
      for (const pn of this.pneu) {
        if (!this.indiceCharge.includes(pn.charge_pneu)) {
          this.indiceCharge.push(pn.charge_pneu);
        }
      }
    },
    setFilterSaison() {
      this.saisonTabFilter = [];
      for (const pn of this.pneu) {
        if (!this.saisonTabFilter.includes(pn.saison_pneu)) {
          this.saisonTabFilter.push(pn.saison_pneu.toUpperCase());
        }
      }
    },
    setFilterIV() {
      this.iv = [];
      for (const pn of this.pneu) {
        if (!this.iv.includes(pn.iv_pneu)) {
          this.iv.push(pn.iv_pneu);
        }
      }
    },
    showRunflat() {
      if (this.runflat) {
        console.log("faire le filtrage");
        this.pneuSaveRunflat = this.pneu;
        this.pneu = this.pneuSave.filter((pn) => {
          return pn.runflat_pneu.toUpperCase() == "OUI";
        });
      } else {
        this.pneu = this.pneuSaveRunflat;
      }
    },
    getBrandPneuList() {
      axios
        .get(
          `${process.env.VUE_APP_API}/configurationBrandPneu/${this.user.proprietaire}`
        )
        .then((response) => {
          for (const brand of response.data.brandList) {
            if (!response.data.brandPneuListToHide.includes(brand)) {
              this.brandPneuList.push(brand);
            }
          }
          this.checkedMarques = response.data.checkedMarques;
          this.firstSearchPneu();
        });
    },
    sortCroissant() {
      this.pneu.sort((a, b) => {
        return a.Prix_euro - b.Prix_euro;
      });
    },
    sortDecroissant() {
      this.pneu.sort((a, b) => {
        return b.Prix_euro - a.Prix_euro;
      });
    },
    async handlerArticle(structure, quantity) {
      var existArticle = false;

      for (const [index, actArticle] of this.actionArticle.entries()) {
        if (
          actArticle.Ref_fournisseur == structure.article.Ref_fournisseur &&
          actArticle.Prefixe_tarif == structure.article.Prefixe_tarif
        ) {
          this.actionArticle[index].quantity =
            parseInt(this.actionArticle[index].quantity) + parseInt(quantity);
          existArticle = true;
        }
      }

      if (existArticle == false) {
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        var dataTab = await prepareArticle(
          structure,
          plateform_mere,
          this.comptoirSessionActive[0].Client[0].Name,
          this.comptoirSessionActive[0].Client[0].Group.Name,
          quantity
        );

        await this.addConsigne(dataTab);
        await this.addactionArticle(dataTab);

        this.refClient = this.noteDocument;

        this.savecomptoirsession({
          id: 6,
          clientFocus: this.comptoirSessionActive[0].Client,
          refClient: this.comptoirSessionActive[0].refClient,
          consigneData: this.comptoirSessionActive[0].consigneData,
          consigneNonData: this.comptoirSessionActive[0].consigneNonData,
          dsvCheck: this.comptoirSessionActive[0].dsvCheck,
        });
      }
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async addConsigne(dataConsigne) {
      const foundConsigne = await axios.post(
        `${process.env.VUE_APP_API}/article/getconsigne`,
        {
          data: dataConsigne,
        }
      );
      if (foundConsigne.data.length > 0) {
        if (this.comptoirSessionActive[0].dsvCheck == false) {
          this.addactionArticle({
            _id: dataConsigne._id + "Consigne",
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: dataConsigne.quantity,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
        }
        // if (this.comptoirSessionActive[0].Client[0].professionnel == true) {
        this.adddataconsigne(dataConsigne);
        this.adddataconsigne({
          _id: dataConsigne._id,
          Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
          Prix_euro: dataConsigne.Montant_consigne,
          Prix_vente: dataConsigne.Montant_consigne,
          PrixFournisseurType: "prixnet",
          disabled: true,
          quantity: dataConsigne.quantity,
          quantityPrep: 0,
          quantityReturned: 0,
          remise: 0,
          prixtotal: dataConsigne.Prix_euro,
          prixfinal: dataConsigne.Prix_euro,
          Code_marque: dataConsigne.Code_marque,
          Description: dataConsigne.Description,
          commentaire: dataConsigne.commentaire,
        });
        // }
      } else {
        if (
          this.comptoirSessionActive[0].Client[0].numcomptedasilva &&
          this.comptoirSessionActive[0].Client[0].numcomptedasilva != ""
        ) {
          this.adddatanonconsigne(dataConsigne);
        }
      }
    },
    toggleAllCheckboxes() {
      if (this.checkedMarques.length === this.brandPneuList.length) {
        this.checkedMarques = []; // Si tous sont cochés, décochez-les
      } else {
        this.checkedMarques = [...this.brandPneuList]; // Sinon, cochez-les tous
      }
    },
  },
  mounted() {
    this.getBrandPneuList();
  },
};
</script>
