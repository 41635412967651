<template>
  <div class="flex justify-between mt-2">
    <router-link to="/" class="flex w-fit">
      <div id="retour">
        <backButton />
      </div>
    </router-link>
    <div class="ml-2 flex space-x-2">
      <button
        @click="openModal()"
        class="bg-bluevk text-white hover:bg-blue-500 shadow-md font-semibold hover:text-white py-2 px-2 h-fit m-auto hover:border-transparent rounded flex justify-center items-center"
      >
        <span> Nouveau</span>
      </button>
      <!-- <button
        @click="openModalIn()"
        class="bg-bluevk text-white hover:bg-blue-500 shadow-md font-semibold hover:text-white py-2 px-2 h-fit m-auto hover:border-transparent rounded flex justify-center items-center"
      >
        <span> Nouveau Instantané </span>
      </button> -->
    </div>
  </div>

  <!-- Modal -->
  <div
    v-if="showModal"
    class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50"
  >
    <div class="bg-white p-6 rounded shadow-lg w-1/3">
      <h2 class="text-lg font-semibold mb-4">Nouveau Transfert</h2>

      <h3 class="font-bold text-red-500">
        {{ errorValider ? "Les structure ne sont pas compatible" : "" }}
      </h3>

      <div v-if="linkValue != null">
        {{ linkValue == true ? "Transfert dépôt" : "Facturation" }}
      </div>

      <!-- De Input -->
      <label class="block text-sm font-medium text-gray-700">De</label>
      <input
        v-model="de"
        type="text"
        class="mt-1 mb-4 p-2 border border-gray-300 rounded w-full"
        placeholder="Entrer De"
      />

      <span
        class="flex material-symbols-rounded justify-center cursor-pointer"
        @click="swapPlateform()"
      >
        swap_vert
      </span>

      <!-- Vers Input -->
      <label class="block text-sm font-medium text-gray-700">Vers</label>
      <select
        v-model="vers"
        @change="checkLink()"
        type="text"
        class="mt-1 mb-4 p-2 border border-gray-300 rounded w-full"
        placeholder="Entrer vers"
      >
        <option
          v-for="(plat, indexPlat) in plateformList"
          :key="indexPlat"
          :value="plat"
        >
          {{ plat }}
        </option>
        <option :value="this.user.proprietaire">
          {{ this.user.proprietaire }}
        </option>
      </select>

      <!-- Valider Button -->
      <div class="flex justify-end mt-6">
        <button
          @click="validerNouveau()"
          v-if="stateValider"
          class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Valider
        </button>
        <button
          @click="showModal = false"
          class="p-3 px-6 py-3 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
        >
          Fermer
        </button>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    v-if="showModalIn"
    class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50"
  >
    <div class="bg-white p-6 rounded shadow-lg w-1/3">
      <h2 class="text-lg font-semibold mb-4">Transfert Instantané</h2>

      <h3 class="font-bold text-red-500">
        {{ errorValider ? "Les structure ne sont pas compatible" : "" }}
      </h3>

      <div v-if="linkValue != null">
        {{ linkValue == true ? "Transfert dépôt" : "Facturation" }}
      </div>

      <!-- De Input -->
      <label class="block text-sm font-medium text-gray-700">De</label>
      <input
        v-model="de"
        type="text"
        class="mt-1 mb-4 p-2 border border-gray-300 rounded w-full"
        placeholder="Entrer De"
      />

      <span
        class="flex material-symbols-rounded justify-center cursor-pointer"
        @click="swapPlateform()"
      >
        swap_vert
      </span>

      <!-- Vers Input -->
      <label class="block text-sm font-medium text-gray-700">Vers</label>
      <select
        v-model="vers"
        @change="checkLink()"
        type="text"
        class="mt-1 mb-4 p-2 border border-gray-300 rounded w-full"
        placeholder="Entrer vers"
      >
        <option
          v-for="(plat, indexPlat) in plateformList"
          :key="indexPlat"
          :value="plat"
        >
          {{ plat }}
        </option>
        <option :value="this.user.proprietaire">
          {{ this.user.proprietaire }}
        </option>
      </select>

      <!-- Valider Button -->
      <div class="flex justify-end mt-6">
        <button
          @click="validerInstantane()"
          v-if="stateValider"
          class="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
        >
          Valider
        </button>
        <button
          @click="showModalIn = false"
          class="p-3 px-6 py-3 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
        >
          Fermer
        </button>
      </div>
    </div>
  </div>

  <!-- Table -->
  <div class="flex flex-col mt-10">
    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full border shadow">
          <thead class="bg-gray-100 text-gray-900">
            <tr>
              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <div class="flex">
                  <span>N°</span>
                </div>
              </th>
              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <div class="flex">
                  <span>Date</span>
                </div>
              </th>

              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <div class="flex">
                  <span>De</span>
                </div>
              </th>

              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <div class="flex">
                  <span>Vers</span>
                </div>
              </th>

              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <div class="flex">
                  <span>Nb Article</span>
                </div>
              </th>

              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <div class="flex">
                  <span>Type</span>
                </div>
              </th>

              <th
                class="px-6 py-1 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200"
              >
                <div class="flex">
                  <span>Statut</span>
                </div>
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(Transfert, indexTransfert) in transfertList"
              :key="indexTransfert"
              @click="selectTransfert(Transfert, 'stock')"
              class="tab-list cursor-pointer odd:bg-white even:bg-slate-50"
            >
              <td
                class="uppercase px-6 py-4 border-b border-gray-200 whitespace-nowrap"
              >
                {{ Transfert.transfert_number }}
              </td>
              <td
                class="uppercase px-6 py-4 border-b border-gray-200 whitespace-nowrap"
              >
                {{ Transfert.Date }}
              </td>
              <td
                class="uppercase px-6 py-4 border-b border-gray-200 whitespace-nowrap"
              >
                {{ Transfert.from }}
              </td>
              <td
                class="uppercase px-6 py-4 border-b border-gray-200 whitespace-nowrap"
              >
                {{ Transfert.to }}
              </td>
              <td
                class="uppercase px-6 py-4 border-b border-gray-200 whitespace-nowrap"
              >
                {{ Transfert.Articles.length }}
              </td>
              <td
                class="uppercase px-6 py-4 border-b border-gray-200 whitespace-nowrap"
              >
                {{
                  Transfert.type == "transfert" ? "Transfert" : "Facturation"
                }}
              </td>
              <td
                class="uppercase px-6 py-4 border-b border-gray-200 whitespace-nowrap"
              >
                {{ Transfert.status == "pending" ? "En attente" : "Validé" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import backButton from "@/components/Button/back.vue";
import { mapGetters, mapActions } from "vuex";
import {
  checkGroupement,
  checkGroupementLink,
  createTransfert,
  getTransfert,
} from "@/hooks/stock/transfertStock.ts";

export default {
  data() {
    return {
      transfertList: [],
      showModal: false,
      showModalIn: false,
      de: "",
      vers: "",
      plateformList: [],
      errorValider: false,
      stateValider: false,
      linkValue: null,
    };
  },
  name: "TransfertHub",
  components: {
    backButton,
  },
  computed: {
    ...mapGetters(["user", "transfertData"]),
  },
  methods: {
    ...mapActions(["setTransfertData"]),
    async getTransfertData(skip, limit, status) {
      let transfert = await getTransfert(
        this.user.proprietaire,
        status,
        skip,
        limit,
        "transfert_number",
        -1,
        this.user._id
      );
      this.transfertList = transfert.data;
    },
    async selectTransfert(data, type) {
      this.setTransfertData(data);
      if (type === "instantane") {
        this.$router.push("transfertInstantane");
      } else {
        this.$router.push("transfertStock");
      }
    },
    async validerNouveau() {
      //create new transfert
      let createT = await createTransfert(
        this.user.proprietaire,
        this.de,
        this.vers,
        this.linkValue == true ? "transfert" : "billed",
        this.user._id
      );
      // save transfert data
      if (createT.data) {
        this.setTransfertData(createT.data);
        this.$router.push("transfertStock");
      }
    },
    async validerInstantane() {
      //create new transfert
      let createT = await createTransfert(
        this.user.proprietaire,
        this.de,
        this.vers,
        this.linkValue == true ? "transfert" : "billed",
        this.user._id
      );
      // save transfert data
      if (createT.data) {
        this.setTransfertData(createT.data);
        this.$router.push("transfertInstantane");
      }
    },
    swapPlateform() {
      if (this.de === this.user.proprietaire) {
        this.de = this.vers;
        this.vers = this.user.proprietaire;
      } else {
        this.vers = this.de;
        this.de = this.user.proprietaire;
      }
      this.checkLink();
    },
    async openModal() {
      let platList = await checkGroupement(
        this.user.proprietaire,
        this.user._id
      );
      this.plateformList = platList.data;
      this.showModal = true;
      this.de = this.user.proprietaire;
    },
    async openModalIn() {
      let platList = await checkGroupement(
        this.user.proprietaire,
        this.user._id
      );
      this.plateformList = platList.data;
      this.showModalIn = true;
      this.de = this.user.proprietaire;
    },
    async checkLink() {
      let chkLink = await checkGroupementLink(
        this.de,
        this.vers,
        this.user._id
      );
      if (chkLink.state === "not found") {
        this.errorValider = true;
        this.stateValider = false;
        this.linkValue = null;
      } else if (chkLink.state === "found") {
        this.errorValider = false;
        this.stateValider = true;
        this.linkValue = chkLink.data;
      }
    },
  },
  async mounted() {
    await this.getTransfertData(0, 100, null);
  },
};
</script>
