<template>
  <div class="flex justify-between">
    <button
      class="flex flex-row items-center cursor-pointer w-54 h-8 bg-bluevk rounded-full hover:bg-blue-500 shadow px-2 text-white"
      @click="backToHub()"
    >
      <span class="material-icons-round"> arrow_back </span>
      <span class="px-2">Retour</span>
    </button>
    <div>
      <h2 class="text-lg font-semibold mb-3">Transfert N°:</h2>
    </div>
    <div class="ml-2">
      <button
        @click="showModal = true"
        class="bg-green-500 text-white hover:bg-green-400 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
      >
        <span> Valider</span>
      </button>
    </div>
  </div>
  <div class="flex justify-between">
    <div class="flex mt-8 ml-2 items-center border shadow-md">
      <p>de</p>
      <span class="material-icons-round mx-1">arrow_forward</span>
      <p>vers</p>
    </div>
    <div class="flex mt-8 mr-3 border shadow-md">etat</div>
  </div>

  <div class="xs:-mt-3 sm:mt-0">
    <!-- PARTIE DE RECHERCHE -->
    <div class="flex flex-col sm:mt-2 space-y-2 xs:text-xs sm:text-base">
      <div
        class="flex xs:flex-col xs:space-y-1 lg:space-y-0 lg:flex-row lg:space-x-2 w-full mt-3"
      >
        <!-- Recherche par EAN -->
        <div class="w-full relative rounded-md shadow-sm">
          <span
            class="absolute material-icons-round inset-y-0 left-0 flex items-center pl-3 text-gray-600"
            >qr_code_scanner</span
          >
          <input
            type="text"
            ref="scan"
            placeholder="Code-barres"
            v-model="articleSearchEAN"
            class="w-full border focus:outline-2 outline-sky-300 px-12 py-2 rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
          />
          <button
            class="absolute material-icons-round xs:text-sm sm:text-base p-1 right-0 bg-bluevk hover:bg-opacity-60 xs:mt-0.5 sm:mt-1 mr-2 text-white shadow-sm px-1 rounded-md"
          >
            search
          </button>
        </div>

        <!-- Recherche par Préfixe ou Marque -->
        <div
          class="flex xs:flex-col xs:space-y-1 lg:space-y-0 lg:flex-row lg:space-x-2 w-full"
        >
          <!-- Recherche par Préfixe -->
          <div class="w-full relative rounded-md shadow-sm">
            <span
              class="absolute material-icons-round inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >local_shipping</span
            >
            <input
              type="text"
              placeholder="Préfixe tarif fournisseur"
              class="w-full border focus:outline-2 outline-sky-300 px-12 py-2 rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
            />
            <button
              class="absolute material-icons-round xs:text-sm sm:text-base p-1 right-0 bg-bluevk hover:bg-opacity-60 xs:mt-0.5 sm:mt-1 mr-2 text-white shadow-sm px-1 rounded-md"
            >
              search
            </button>
          </div>

          <!-- Recherche par Marque -->
          <div class="w-full relative rounded-md shadow-sm">
            <span
              class="absolute material-icons-round inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >label
            </span>
            <input
              type="text"
              placeholder="Marque"
              class="w-full border focus:outline-2 outline-sky-300 px-12 py-2 rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
            />
            <button
              class="absolute material-icons-round xs:text-sm sm:text-base p-1 right-0 bg-bluevk hover:bg-opacity-60 xs:mt-0.5 sm:mt-1 mr-2 text-white shadow-sm px-1 rounded-md"
            >
              search
            </button>
          </div>
        </div>
      </div>

      <!-- RESPONSE -->
      <div
        class="mt-2 bg-white p-4 inline-block h-fit min-w-full align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <h1 class="text-bluevk">Résultat :</h1>
        <table
          class="p-2 border shadow-sm mt-1 min-w-full"
          style="width: 65rem"
        >
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Réf. / Marque
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Description / Préfixe tarif
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Stock
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Réservé
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Commande fourn.
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                min / max
              </th>
            </tr>
          </thead>
          <tbody class="bg-white max-h-36 overflow-y-auto text-sm">
            <tr
              class="cursor-pointer odd:bg-white even:bg-gray-100 hover:bg-bluevk hover:text-white border-black hover:shadow-lg"
            >
              <td class="px-2 border-b border-gray-200 whitespace-nowrap">
                <p>Référence Fournisseur</p>
                <p>Code Marque</p>
              </td>

              <td class="px-2 border-b border-gray-200 whitespace-nowrap">
                <p>Description de l'article</p>
                <p class="text-md text-orangevk">Préfixe tarif</p>
              </td>

              <td class="px-2 border-b border-gray-200 whitespace-nowrap">
                <p>Stock</p>
              </td>

              <td class="px-2 border-b border-gray-200 whitespace-nowrap">
                <p>Réservé</p>
              </td>

              <td class="px-2 border-b border-gray-200 whitespace-nowrap">
                <p>Commande fournisseur</p>
              </td>

              <td class="px-2 border-b border-gray-200 whitespace-nowrap">
                <p>Min</p>
                <p>Max</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- TABLEAU -->
      <div class="flex">
        <div class="w-full bg-white shadow rounded mt-2 p-2">
          <!-- Informations de l'article -->
          <div class="xs:flex xs:space-x-1 sm:block sm:space-x-0">
            <div class="lg:mt-4 lg:flex lg:space-x-4">
              <!-- Référence -->
              <div class="w-full relative xs:text-xs sm:text-sm">
                <input
                  type="text"
                  id="reference"
                  disabled
                  class="block px-2.5 pb-1 pt-2 w-full sm:text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                />
                <label
                  for="reference"
                  class="absolute sm:text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
                >
                  Référence
                </label>
              </div>

              <!-- Marque -->
              <div class="hidden lg:block w-full relative sm:text-sm">
                <input
                  type="text"
                  id="marque"
                  disabled
                  class="block px-2.5 pb-1 pt-2 w-full sm:text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                />
                <label
                  for="marque"
                  class="absolute sm:text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
                >
                  Marque
                </label>
              </div>

              <!-- Description -->
              <div class="hidden lg:block w-full relative sm:text-sm">
                <input
                  type="text"
                  id="description"
                  disabled
                  class="block px-2.5 pb-1 pt-2 w-full sm:text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                />
                <label
                  for="description"
                  class="absolute sm:text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
                >
                  Description
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="lg:mt-4 lg:flex lg:space-x-4">
        <!-- Préfixe tarif -->
        <div class="hidden lg:block w-fit relative sm:text-sm">
          <input
            type="text"
            id="prefixe"
            disabled
            class="block px-2.5 pb-1 pt-2 w-full sm:text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
          />
          <label
            for="prefixe"
            class="absolute sm:text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
          >
            Préfixe tarif
          </label>
        </div>

        <!-- Adresse de l'article -->
        <div class="xs:w-full sm:w-fit relative">
          <div class="flex xs:text-xs">
            <input
              type="text"
              id="adresse"
              class="block pl-2.5 pr-7 pb-1 pt-2 w-full sm:text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
            />
            <label
              for="adresse"
              class="absolute sm:text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
            >
              Adresse
            </label>
            <button
              class="absolute material-icons-round right-0 xs:mt-0.5 sm:mt-1 bg-orangevk hover:bg-opacity-60 mr-2 text-white shadow-sm px-1 rounded-md"
            >
              done
            </button>
          </div>
        </div>
      </div>

      <!-- Tableau de stock/réservé/commande fournisseur -->
      <div class="hidden lg:flex lg:flex-row lg:mt-6">
        <!-- Stock total -->
        <div class="w-1/3 relative">
          <div class="flex flex-col items-center">
            <input
              type="number"
              class="w-36 text-5xl border rounded-md shadow-sm text-bluevk outline-2 focus:outline-sky-300"
            />
            <span>Stock total</span>
          </div>
        </div>

        <!-- Réservé Client -->
        <div class="w-1/3 relative">
          <div class="flex flex-col items-center">
            <div class="flex">
              <input
                type="number"
                class="w-36 text-5xl border rounded-md shadow-sm text-bluevk outline-2 focus:outline-sky-300"
              />
              <button
                class="material-icons-round text-4xl bg-gray-100 hover:bg-gray-200 border text-orangevk shadow-sm px-2 rounded-md ml-2"
              >
                arrow_forward_ios
              </button>
            </div>
            <span>Réservé client</span>
          </div>
        </div>

        <!-- Commande Fournisseur -->
        <div class="w-1/3 relative">
          <div class="flex flex-col items-center">
            <div class="flex">
              <input
                type="number"
                class="w-36 text-5xl border rounded-md shadow-sm text-bluevk outline-2 focus:outline-sky-300"
              />
              <button
                class="material-icons-round text-4xl bg-gray-100 hover:bg-gray-200 border text-orangevk shadow-sm px-2 rounded-md ml-2"
              >
                arrow_forward_ios
              </button>
            </div>
            <span>Commande fournisseur</span>
          </div>
        </div>
      </div>

      <!-- SUR PC -->
      <div class="hidden border shadow-lg rounded-md lg:block">
        <div class="flex flex-row mt-8">
          <div class="w-1/3">
            <div
              class="flex flex-col items-center shadow rounded cursor-pointer"
              @click="setNumber('1')"
            >
              <span class="text-6xl">1</span>
            </div>
          </div>
          <div class="w-1/3">
            <div
              class="flex flex-col items-center shadow rounded cursor-pointer"
              @click="setNumber('2')"
            >
              <span class="text-6xl">2</span>
            </div>
          </div>
          <div class="w-1/3">
            <div
              class="flex flex-col items-center shadow rounded cursor-pointer"
              @click="setNumber('3')"
            >
              <span class="text-6xl">3</span>
            </div>
          </div>
        </div>
        <div class="flex flex-row mt-4">
          <div class="w-1/3">
            <div
              class="flex flex-col items-center shadow rounded cursor-pointer"
              @click="setNumber('4')"
            >
              <span class="text-6xl">4</span>
            </div>
          </div>
          <div class="w-1/3">
            <div
              class="flex flex-col items-center shadow rounded cursor-pointer"
              @click="setNumber('5')"
            >
              <span class="text-6xl">5</span>
            </div>
          </div>
          <div class="w-1/3">
            <div
              class="flex flex-col items-center shadow rounded cursor-pointer"
              @click="setNumber('6')"
            >
              <span class="text-6xl">6</span>
            </div>
          </div>
        </div>
        <div class="flex mt-4">
          <div class="w-1/3">
            <div
              class="flex flex-col items-center shadow rounded cursor-pointer"
              @click="setNumber('7')"
            >
              <span class="text-6xl">7</span>
            </div>
          </div>
          <div class="w-1/3">
            <div
              class="flex flex-col items-center shadow rounded cursor-pointer"
              @click="setNumber('8')"
            >
              <span class="text-6xl">8</span>
            </div>
          </div>
          <div class="w-1/3">
            <div
              class="flex flex-col items-center shadow rounded cursor-pointer"
              @click="setNumber('9')"
            >
              <span class="text-6xl">9</span>
            </div>
          </div>
        </div>
        <div class="flex mt-4 justify-center">
          <div class="w-1/3">
            <div
              class="flex flex-col items-center shadow rounded cursor-pointer"
              @click="setNumber('0')"
            >
              <span class="text-6xl">0</span>
            </div>
          </div>
        </div>
        <!-- Réinitialiser la quantité -->
        <div class="flex justify-center space-x-4 mt-4">
          <div class="w-1/4">
            <div
              class="flex flex-col items-center shadow rounded text-white bg-red-500 cursor-pointer"
              @click="deleteNumber()"
            >
              <span class="text-2xl p-2">Supprimer</span>
            </div>
          </div>
        </div>
      </div>

      <!-- SUR TEL -->
      <div class="lg:hidden">
        <div class="flex mt-2 space-x-2">
          <!-- PARTIE GAUCHE -->
          <div class="flex flex-col">
            <div class="flex flex-col items-center">
              <span class="text-blue-800">+</span>
              <label class="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  v-model="stateNumber"
                  class="sr-only peer"
                />
                <div
                  class="h-7 w-4 bg-blue-700 peer-focus:outline-none text-left dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-blue-800 peer-checked:after:translate-y-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-blue-800 peer-checked:bg-orange-500"
                ></div>
              </label>
              <span class="text-orange-500">=</span>
            </div>
            <div
              class="flex flex-col space-y-1 overflow-y-auto border border-gray-200 p-0.5 pr-1.5 rounded-md shadow-lg"
              style="max-height: 200px"
            >
              <div v-for="(num, index) in numbers" :key="index">
                <button>
                  <p
                    class="w-8 border rounded-md shadow-lg font-medium text-white bg-gray-500 hover:bg-gray-800 border border-gray-800"
                    @click="setNumberStock(num)"
                  >
                    {{ num }}
                  </p>
                  <p v-if="index < numbers.length - 1" class="text-center">
                    --
                  </p>
                </button>
              </div>
            </div>
          </div>

          <!-- PARTIE DROITE -->
          <div class="flex">
            <div class="flex flex-col space-y-2 align-center">
              <div
                class="flex bg-green-500 w-full rounded shadow cursor-pointer"
                @click="upInputStock()"
              >
                <span
                  class="material-symbols-rounded text-7xl text-white mx-auto"
                >
                  keyboard_arrow_up
                </span>
              </div>
              <div class="w-full relative rounded-md shadow-sm">
                <input
                  type="text"
                  @keyup.enter="updateStock(false)"
                  class="w-full text-center focus:outline-2 outline-sky-300 py-4 pl-2 pr-12 text-white text-5xl bg-bluevk shadow rounded"
                />
                <button
                  @click="updateStock(false)"
                  class="absolute material-icons-round my-3 py-4 px-1 text-4xl right-0 hover:bg-white hover:bg-opacity-60 mr-0.5 border text-white shadow-sm px-1 rounded-md"
                >
                  arrow_forward_ios
                </button>
              </div>
              <div
                class="flex bg-red-500 w-full rounded shadow cursor-pointer"
                @click="downInputStock()"
              >
                <span
                  class="material-symbols-rounded text-7xl text-white mx-auto"
                >
                  keyboard_arrow_down
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <audio id="son1" controls class="hidden">
      <source src="../assets/sonTest1.ogg" type="audio/ogg" />
    </audio>
    <audio id="son2" controls class="hidden">
      <source src="../assets/sonTest2.ogg" type="audio/ogg" />
    </audio>
  </div>
</template>
<script>
export default {
  name: "TransfertInstantane",
  data() {
    return {
      numbers: [2, 5, 10, 20, 50, 100],
      stateNumber: false,
    };
  },
  components: {},
  methods: {
    backToHub() {
      this.$router.push("/transfertHub");
    },
    
  },
};
</script>
