<template>
  <!-- modal Valider -->
  <div
    v-if="showModal"
    :class="`modal ${
      !showModal && 'opacity-0 pointer-events-none'
    } z-50 fixed   w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showModal = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-height: 50rem; max-width: 50rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <div class="px-6 py-4 modal-content">
        <div class="flex justify-between pb-3">
          <div>
            <p class="text-xl">Voulez-vous confirmer le transfert de stock ?</p>
          </div>
        </div>

        <div class="flex flex-col space-y-4">
          <div>
            {{
              transfertData.type == "transfert"
                ? "Transfert dépôt"
                : "Facturation"
            }}
          </div>

          <div>{{ transfertData.from }} -> {{ transfertData.to }}</div>

          <div class="max-h-60 overflow-y-auto">
            <div class="grid grid-cols-4 gap-2">
              <div
                v-for="(art, index) in transfertData.Articles"
                :key="index"
                class="flex items-center justify-between p-2 bg-gray-200 rounded shadow"
              >
                <div class="flex flex-col">
                  <p class="text-sm">{{ art.Ref_fournisseur }}</p>
                  <p class="text-xs">{{ art.Code_marque }}</p>
                </div>
                <div class="flex flex-col">
                  <p class="text-sm text-gray-400">Quantité</p>
                  <p class="text-xs text-center">
                    {{ art.quantity }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-end pt-2">
          <button
            @click="showModal = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            @click="valideStock()"
            class="p-3 px-6 py-3 mr-2 text-white bg-blue-500 rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Valider
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal Valider -->
  <div class="flex justify-between">
    <button
      class="flex flex-row items-center cursor-pointer w-54 h-8 bg-bluevk rounded-full hover:bg-blue-500 shadow px-2 text-white"
      @click="backToHub()"
    >
      <span class="material-icons-round"> arrow_back </span>
      <span class="px-2">Retour</span>
    </button>
    <div>
      <h2 class="text-lg font-semibold mb-3">
        Transfert N°: {{ transfertData.transfert_number }}
      </h2>
    </div>
    <div class="ml-2" v-if="transfertData.status == 'pending'">
      <button
        @click="showModal = true"
        class="bg-green-500 text-white hover:bg-green-400 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
      >
        <span> Valider</span>
      </button>
    </div>
  </div>
  <div class="flex justify-between items-center mt-8">
    <div class="flex ml-2 items-center border shadow-md">
      <p>{{ transfertData.from }}</p>
      <span class="material-icons-round mx-1">arrow_forward</span>
      <p>{{ transfertData.to }}</p>
    </div>
    <div class="flex flex-row space-x-4">
      <div>
        <div
          class="w-[18rem] h-[2rem] m-auto flex flex-row space-x-2 justify-center relative"
        >
          <div>
            <input
              v-model="searchMarque"
              placeholder="Marque"
              type="text"
              class="focus:outline-2 h-[2rem] outline-sky-300 p-2 border rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
            />
          </div>

          <div>
            <span
              class="material-icons-outlined cursor-pointer text-blue-500"
              @click="checkBrand()"
            >
              search
            </span>
          </div>
        </div>
        <div
          class="w-[18rem] flex flex-col space-y-2"
          v-if="marqueList.length > 0"
        >
          <button
            class="w-full bg-white rounded-sm border border-gray-300 p-2 text-gray-500"
            @click="selectedBrand.push(bdList), (marqueList = [])"
            v-for="(bdList, indexB) in marqueList"
            :key="indexB"
          >
            {{ bdList }}
          </button>
        </div>
        <div class="grid grid-cols-3 gap-1">
          <button
            v-for="(m, indexM) in selectedBrand"
            :key="indexM"
            class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
            @click="selectedBrand.splice(indexM, 1)"
          >
            {{ m }}
          </button>
        </div>
      </div>
      <div>
        <div
          class="w-[18rem] h-[2rem] m-auto flex flex-row space-x-2 justify-center"
        >
          <div>
            <input
              v-model="searchPrefixe_tarif"
              @keyup:enter="checkSupplier()"
              placeholder="Fournisseur"
              type="text"
              class="focus:outline-2 h-[2rem] outline-sky-300 p-2 border rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
            />
          </div>

          <div>
            <span
              class="material-icons-outlined cursor-pointer text-blue-500"
              @click="checkSupplier()"
            >
              search
            </span>
          </div>
        </div>
        <div class="grid grid-cols-3 gap-1" v-if="selected_Prefixe_tarif">
          <span
            class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 cursor-pointer"
            @click="selected_Prefixe_tarif = ''"
            >{{ selected_Prefixe_tarif }}</span
          >
        </div>
      </div>
    </div>
    <div class="flex mr-3 border shadow-md">
      {{ transfertData.status == "pending" ? "En cours" : "Validé" }}
    </div>
  </div>
  <div class="relative mt-8 rounded-md shadow-sm">
    <span
      class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
    >
      <span class="material-icons-round">qr_code_scanner</span>
    </span>
    <div>
      <input
        type="text"
        ref="inputEAN"
        placeholder="Code-barres"
        v-model="articleSearchTerm"
        v-on:keyup.enter="getOneArticlebyEAN()"
        class="border w-full focus:outline-2 outline-sky-300 px-9 py-2 rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
      />
    </div>
  </div>
  <div class="relative mt-2 rounded-md shadow-sm">
    <span
      class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
    >
      <span class="material-icons-round">keyboard</span>
    </span>
    <div>
      <input
        type="text"
        ref="inputRef"
        placeholder="Référence"
        v-model="refSearchTerm"
        v-on:keyup.enter="getOneArticlebyRef()"
        class="border w-full focus:outline-2 outline-sky-300 px-9 py-2 rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
      />
    </div>
  </div>
  <div class="flex mt-2 space-x-2 sm:justify-center">
    <!-- PARTIE GAUCHE -->
    <div class="flex flex-col">
      <div class="flex flex-col items-center">
        <span class="text-blue-800">+</span>
        <label class="relative inline-flex items-center cursor-pointer">
          <input type="checkbox" v-model="stateNumber" class="sr-only peer" />
          <div
            class="h-7 w-4 bg-blue-700 peer-focus:outline-none text-left dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-blue-800 peer-checked:after:translate-y-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-blue-800 peer-checked:bg-orange-500"
          ></div>
        </label>
        <span class="text-orange-500">=</span>
      </div>
      <div
        class="flex flex-col space-y-1 overflow-y-auto border border-gray-200 p-0.5 pr-1.5 rounded-md shadow-lg"
        style="max-height: 200px"
      >
        <div v-for="(num, index) in numbers" :key="index">
          <button>
            <p
              class="w-8 border rounded-md shadow-lg font-medium text-white bg-gray-500 hover:bg-gray-800 border border-gray-800"
              @click="setNumberStock(num)"
            >
              {{ num }}
            </p>
            <p v-if="index < numbers.length - 1" class="text-center">--</p>
          </button>
        </div>
      </div>
    </div>

    <!-- PARTIE DROITE -->
    <div class="flex my-auto">
      <div class="flex flex-col space-y-2 align-center">
        <p class="font-medium">
          {{ scannedData.Ref_fournisseur }}
        </p>
        <div
          class="flex bg-green-500 w-full rounded shadow cursor-pointer"
          @click="scannedData.quantity = scannedData.quantity + 1"
        >
          <span class="material-symbols-rounded text-7xl text-white mx-auto">
            keyboard_arrow_up
          </span>
        </div>
        <div class="w-full relative rounded-md shadow-sm">
          <input
            type="text"
            v-model="scannedData.quantity"
            class="w-full text-center focus:outline-2 outline-sky-300 py-4 pl-2 pr-12 text-white text-5xl bg-bluevk shadow rounded"
            @change="updateTransfert()"
          />
          <button
            class="absolute material-icons-round my-3 py-4 px-1 text-4xl right-0 hover:bg-white hover:bg-opacity-60 mr-0.5 border text-white shadow-sm px-1 rounded-md"
          >
            arrow_forward_ios
          </button>
        </div>
        <div
          class="flex bg-red-500 w-full rounded shadow cursor-pointer"
          @click="scannedData.quantity = scannedData.quantity - 1"
        >
          <span class="material-symbols-rounded text-7xl text-white mx-auto">
            keyboard_arrow_down
          </span>
        </div>
        <div v-if="tableauInverse.length > 1">
          <p
            v-if="
              scannedData.Ref_fournisseur == tableauInverse[0].Ref_fournisseur
            "
            class="font-medium"
          >
            {{ tableauInverse[1].Ref_fournisseur }}
          </p>
          <p v-else class="font-medium">
            {{ tableauInverse[0].Ref_fournisseur }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- TABLEAU -->
  <div class="py-2 my-2 overflow-x-auto mt-1 sm:mx-6 sm:px-6 lg:mx-8 lg:px-8">
    <div
      class="inline-block min-w-full overflow-hidden align-middle border-2 rounded-md border-gray-200 shadow"
    >
      <table class="min-w-full">
        <thead>
          <tr>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Réf.
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Quantité
            </th>
          </tr>
        </thead>

        <tbody class="bg-white overflow-y-auto max-h-32">
          <tr
            v-for="(article, indexArticle) in tableauInverse"
            :key="indexArticle"
          >
            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <div class="flex flex-row space-x-2">
                <div>{{ article.Ref_fournisseur }}</div>
                <div>
                  <span
                    class="material-symbols-rounded text-red-500 cursor-pointer"
                    @click="
                      deleteArticle(
                        article.Ref_fournisseur,
                        article.Code_marque
                      )
                    "
                  >
                    backspace
                  </span>
                </div>
              </div>
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <input
                class="w-20 border border-gray-300 rounded-md"
                type="number"
                v-model="article.quantity"
                @change="updateTransfert()"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import sonTest1 from "../assets/sontest1.mp3";
import sonTest2 from "../assets/sontest2.mp3";
import sonTest3 from "../assets/sontest3.mp3";
import {
  getInventoryLines,
  searchRef,
  scanRef,
} from "@/hooks/stock/inventaire.ts";
import {
  updateTransfert,
  transfertStock,
  billStock,
} from "@/hooks/stock/transfertStock.ts";
import axios from "axios";
import { createReception } from "@/hooks/commandeFournisseur/commandeFournisseur.ts";
import { getPrixAchatSorted } from "@/hooks/articles/articles.ts";
export default {
  name: "TransfertStock",
  data() {
    return {
      showModal: false,
      numbers: [2, 5, 10, 20, 50, 100],
      stateNumber: false,
      scanquantity: 0,
      articleSearchTerm: "",
      refSearchTerm: "",
      searchMarque: "",
      searchPrefixe_tarif: "",
      selectedBrand: ["BREMBO"],
      selected_Prefixe_tarif: "Vhi",
      marqueList: [],
      scannedData: {},
    };
  },
  components: {},
  computed: {
    ...mapGetters(["user", "transfertData"]),
    tableauInverse() {
      if (
        this.transfertData.Articles &&
        this.transfertData.Articles.length > 0
      ) {
        return this.transfertData.Articles.slice().reverse();
      } else {
        return [];
      }
    },
  },
  methods: {
    async getOneArticlebyEAN() {
      if (this.articleSearchTerm !== "") {
        //initialisation des Variables
        this.errorBrand = false;
        const son1 = new Audio(sonTest1);
        const son2 = new Audio(sonTest2);
        const son3 = new Audio(sonTest3);

        //chercher la ref dans la base de donnée
        const res = await scanRef(
          this.user._id,
          this.articleSearchTerm,
          this.selectedBrand,
          this.selected_Prefixe_tarif,
          this.user.proprietaire
        );

        // analyser si la ref existe ou pas
        if (res.message == "not found") {
          son2.play();
          this.articleSearchTerm = "";
          this.focusInput();
        } else if (res.message == "found") {
          //Commencer par init la ref recherché
          this.articleSearchTerm = "";

          let art = res.data.article;

          art["quantity"] = 1;

          //Verifier si l'article existe deja dans le tableau
          const foundIndex = this.transfertData.Articles.findIndex(
            (element) =>
              element.Ref_fournisseur === res.data.article.Ref_fournisseur
          );

          // Jouer son en fonction de l'existence de l'article dans l'inventaire
          if (foundIndex !== -1) {
            son3.play();
            this.transfertData.Articles[foundIndex].quantity++;
            this.scannedData = this.transfertData.Articles[foundIndex];
          } else {
            son1.play();
            this.transfertData.Articles.push(art);
            this.scannedData = art;
          }

          //Ajouter l'article dans l'inventaire
          this.scannedRef = res.data.article.Ref_fournisseur;
        } else if (res.message == "found Article") {
          //Commencer par init la ref recherché
          this.articleSearchTerm = "";

          let art = res.data;

          art["quantity"] = 1;

          //Verifier si l'article existe deja dans le tableau
          const foundIndex = this.transfertData.Articles.findIndex(
            (element) => element.Ref_fournisseur === res.data.Ref_fournisseur
          );

          // Jouer son en fonction de l'existence de l'article dans l'inventaire
          if (foundIndex !== -1) {
            son3.play();
            this.transfertData.Articles[foundIndex].quantity++;
            this.scannedData = this.transfertData.Articles[foundIndex];
          } else {
            son1.play();
            this.transfertData.Articles.push(art);
            this.scannedData = art;
          }

          this.scannedRef = res.data.Ref_fournisseur;
        }
        this.articleSearchTerm = "";
        this.updateTransfert();
      }
    },
    async getOneArticlebyRef() {
      if (this.refSearchTerm !== "") {
        //initialisation des Variables
        this.errorBrand = false;
        const son1 = new Audio(sonTest1);
        const son2 = new Audio(sonTest2);
        const son3 = new Audio(sonTest3);

        //chercher la ref dans la base de donnée
        const res = await searchRef(
          this.user._id,
          this.refSearchTerm,
          this.selectedBrand,
          this.selected_Prefixe_tarif,
          this.user.proprietaire
        );

        // analyser si la ref existe ou pas
        if (res.message == "not found") {
          son2.play();
          this.refSearchTerm = "";
          this.focusInput();
        } else if (res.message == "found") {
          //Commencer par init la ref recherché
          this.refSearchTerm = "";

          let art = res.data.article;

          art["quantity"] = 1;

          //Verifier si l'article existe deja dans le tableau
          const foundIndex = this.transfertData.Articles.findIndex(
            (element) =>
              element.Ref_fournisseur === res.data.article.Ref_fournisseur
          );

          // Jouer son en fonction de l'existence de l'article dans l'inventaire
          if (foundIndex !== -1) {
            son3.play();
            this.transfertData.Articles[foundIndex].quantity++;
            this.scannedData = this.transfertData.Articles[foundIndex];
          } else {
            son1.play();
            this.transfertData.Articles.push(art);
            this.scannedData = art;
          }

          //Ajouter l'article dans l'inventaire
          this.scannedRef = res.data.article.Ref_fournisseur;
        } else if (res.message == "found Article") {
          //Commencer par init la ref recherché
          this.refSearchTerm = "";

          let art = res.data;

          art["quantity"] = 1;

          //Verifier si l'article existe deja dans le tableau
          const foundIndex = this.transfertData.Articles.findIndex(
            (element) => element.Ref_fournisseur === res.data.Ref_fournisseur
          );

          // Jouer son en fonction de l'existence de l'article dans l'inventaire
          if (foundIndex !== -1) {
            son3.play();
            this.transfertData.Articles[foundIndex].quantity++;
            this.scannedData = this.transfertData.Articles[foundIndex];
          } else {
            son1.play();
            this.transfertData.Articles.push(art);
            this.scannedData = art;
          }

          this.scannedRef = res.data.Ref_fournisseur;
        }
        this.refSearchTerm = "";
        this.updateTransfert();
      }
    },
    focusInput() {
      this.$refs.inputEAN.focus();
    },
    setNumberStock(number) {
      if (!this.stateNumber) {
        this.scannedData.quantity =
          parseInt(this.scannedData.quantity) + number;
        this.updateTransfert();
      } else {
        this.scannedData.quantity = number;
        this.updateTransfert();
      }
    },
    async updateTransfert() {
      const res = await updateTransfert(
        this.user.proprietaire,
        this.transfertData._id,
        this.transfertData.Articles,
        this.transfertData.status,
        false,
        this.user._id
      );
    },
    async checkBrand() {
      if (this.searchMarque != "") {
        this.searchMarque = this.searchMarque.toUpperCase();
        this.searchMarque = this.searchMarque.replace(/ /g, "");
        const chkBrand = await axios.post(
          `${process.env.VUE_APP_API}/inventaire/checkBrand`,
          {
            plateform: this.user.proprietaire,
            brand: this.searchMarque,
          }
        );
        if (chkBrand.data && chkBrand.data != false) {
          for (const brand of chkBrand.data) {
            this.marqueList.push(brand.mfrName);
          }
        }
      }
    },
    async checkSupplier() {
      if (this.searchPrefixe_tarif) {
        //axios call
        const chkSupplier = await axios.post(
          `${process.env.VUE_APP_API}/fournisseur/searchInPlateform`,
          {
            plateform: this.user.proprietaire,
            searchTerm: this.searchPrefixe_tarif,
            skip: 0,
            limit: 50,
          }
        );
        if (chkSupplier.data.results.length === 1) {
          this.selected_Prefixe_tarif =
            chkSupplier.data.results[0].Prefixe_tarif;
        }
      }
    },
    async deleteArticle(Ref_fournisseur, Code_marque) {
      const index = this.transfertData.Articles.findIndex(
        (element) =>
          element.Ref_fournisseur === Ref_fournisseur &&
          element.Code_marque === Code_marque
      );
      this.transfertData.Articles.splice(index, 1);

      await this.updateTransfert();
    },
    async valideStock() {
      if (this.transfertData.Articles.length > 0) {
        if (this.transfertData.type == "transfert") {
          await this.processTransfertStock();
        } else if (this.transfertData.type == "billed") {
          await this.billStock();
        }
      }
    },
    async processTransfertStock() {
      //process a transfert of stock
      let res = await transfertStock(
        this.user.proprietaire,
        this.transfertData._id,
        this.user._id
      );

      if (res.state == "transferred") {
        this.$router.push("/transfertHub");
      }
    },
    async billStock() {
      //create a BL
      let ArticlesTab = [];
      let pdfinfo = [];
      let Prix_Total = 0.0;
      const filialeInformationFrom = await axios.get(
        `${process.env.VUE_APP_API}/plateform/getone/${this.transfertData.from}`
      );
      const filialeInformationTo = await axios.get(
        `${process.env.VUE_APP_API}/plateform/getone/${this.transfertData.to}`
      );
      const getCoef = await axios.post(
        `${process.env.VUE_APP_API}/plateform/getCoef`,
        {
          plateform: this.transfertData.from,
          plateform_receiver: this.transfertData.to,
        }
      );
      for (const groupArt of this.transfertData.Articles) {
        let art_pa = await getPrixAchatSorted(
          this.transfertData.from,
          groupArt.Ref_fournisseur,
          groupArt.Code_EAN,
          groupArt.Prefixe_tarif
        );
        if (art_pa == null) {
          art_pa = groupArt.Prix_euro;
        }

        let art_data = { ...groupArt };
        art_data.PrixFournisseurType = "prixnet";
        art_data.Prix_vente = art_pa * getCoef.data;
        art_data.prix_net = art_pa * getCoef.data;
        art_data.quantity_received = 0;
        art_data.quantity_asked = parseInt(groupArt.quantity);

        ArticlesTab.push(art_data);

        Prix_Total += art_data.Prix_vente * art_data.quantity;

        pdfinfo.push({
          description: art_data.Description,
          ref: art_data.Ref_fournisseur,
          quantite: art_data.quantity,
          remise: 0,
          commentaire: art_data.commentaire ? art_data.commentaire : "",
          remisegenerale: 0,
          prixU: art_data.Prix_vente,
          total: art_data.Prix_vente * art_data.quantity,
          totalSansRemise: art_data.Prix_vente * art_data.quantity,
        });
      }

      let numDoc = 0;
      await axios
        .post(`${process.env.VUE_APP_API}/bdl/getlast`, {
          uid: this.user.proprietaire,
        })
        .then((response) => {
          numDoc = response.data + 1;
        });

      let dataBL = {
        Name: this.user.username,
        Owner: this.transfertData.from,
        Date: new Date(),
        Numero: numDoc,
        TotalPrice: Prix_Total,
        TotalPriceTTC: Prix_Total + Prix_Total * 0.2,
        Client: this.transfertData.to,
        IsProcessed: false,
        Note:
          "Transfert de stock de" +
          this.transfertData.from +
          " vers " +
          this.transfertData.to,
        Articles: ArticlesTab,
        reglement: [],
        pdfData: pdfinfo,
        clientInfo: filialeInformationTo,
        Transport: 0,
        Fournisseur: "",
        textFile: {
          consignataire: "",
          typeBL: "",
          codeClient: "",
          numeroCmd: "",
          commentaire: "",
        },
      };

      await axios.post(`${process.env.VUE_APP_API}/bdl`, dataBL);

      //Create a reception
      await createReception(
        this.user.username,
        this.transfertData.to,
        this.transfertData.from,
        ArticlesTab,
        "Commande Inter Depot",
        "Stock",
        [dataBL],
        [],
        this.transfertData._id
      );

      //change the stock
      let res = await billStock(
        this.user.proprietaire,
        this.transfertData._id,
        this.user._id
      );
      if (res.state == "transferred") {
        this.$router.push("/transfertHub");
      }
    },
    backToHub() {
      this.$router.push("/transfertHub");
    },
  },
};
</script>
